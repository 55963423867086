import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useGetSubCategoryList } from "../../hooks/useGetSubCategoryList";
import { useGetProductList } from "../../hooks/useGetProductList";
import { useGetServiceList } from "../../hooks/useGetServiceList";

import ListCategories from "../../components/ListCategories";

import "./style.css"

import chevron_icon from './../../assets/svg/chevron_icon-nav-desk.svg'

function Menu() {
    const goPage = useGoPage();

    const getSubCategoryList = useGetSubCategoryList();
    const getProductList = useGetProductList();
    const getServiceList = useGetServiceList();

    const data = useSelector(state => state.data);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const nav_menu = useSelector(state => state.menu.nav_menu);
    const nav_type = useSelector(state => state.menu.nav_type);
    const menu_desktop = useSelector(state => state.menu.menu_desktop);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [products, setProducts] = useState([]);

    const [categorySelected, setCategorySelected] = useState({ route: 'category', _id: '' });
    const [subCategorySelected, setSubCategorySelected] = useState({ route: '', _id: '' });
    const [productSelected, setProductSelected] = useState({ route: '', _id: '' });

    // Affichage de la 1ere liste
    useEffect(() => {
        if (data && data.categories.length !== 0) {
            const newCategories = data.categories.filter(c => !c.parentId && c.type === (nav_menu === 1 ? 'prod' : 'serv'));
            setCategories(newCategories);
        }
    }, [data, nav_menu])

    // Mise à jour de l'affichage de la 2eme liste
    useEffect(() => {
        fetchDataList('subCategories', setSubCategories, setSubCategorySelected, categorySelected._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, categorySelected, nav_menu])

    // Mise à jour de l'affichage de la 3eme liste
    useEffect(() => {
        fetchDataList('products', setProducts, setProductSelected, subCategorySelected._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, subCategorySelected, nav_menu])

    // Filtrage pour afficher les sous-éléments d'un élément selectioné
    function fetchDataList(typeFecth, setList, setListSelected, elementSelected) {
        if (typeFecth === 'subCategories') {
            setSubCategorySelected({ route: '', _id: '' });
            setSubCategories([]);
        }

        setProductSelected({ route: '', _id: '' });
        setProducts([]);

        if (nav_menu === 1) {
            const childrensCategory = getSubCategoryList('prod', elementSelected);
            const childrensProduct = getProductList(elementSelected);

            setList(childrensCategory.length !== 0 ? childrensCategory : childrensProduct);
            setListSelected(prev => { return { ...prev, route: childrensCategory.length !== 0 ? 'category' : 'product' } });
        }

        if (nav_menu === 2) {
            const childrensCategory = getSubCategoryList('serv', elementSelected);
            const childrensService = getServiceList(elementSelected);

            setList(childrensCategory.length !== 0 ? childrensCategory : childrensService);
            setListSelected(prev => { return { ...prev, route: childrensCategory.length !== 0 ? 'category' : 'service' } });
        }
    }


    return nav_menu !== 0 && nav_type === 1 && menu_desktop && (
        <div className="menu">
            <div className="content">
                <h3>{nav_menu === 1 ? trad.title_menu_prod[lang] : trad.title_menu_serv[lang]}</h3>

                <ListCategories
                    icon={chevron_icon}
                    list={categories}
                    selected={categorySelected}
                    selector={setCategorySelected}
                    onClick={() => goPage(`category/${categorySelected._id}`)}
                />

                {subCategories && subCategories.length !== 0 &&
                    <ListCategories
                        icon={chevron_icon}
                        list={subCategories}
                        selected={subCategorySelected}
                        selector={setSubCategorySelected}
                        onClick={() => goPage(`${subCategorySelected.route}/${subCategorySelected._id}`)}
                    />
                }

                {products && products.length !== 0 &&
                    <ListCategories
                        icon={chevron_icon}
                        list={products}
                        selected={productSelected}
                        selector={setProductSelected}
                        onClick={() => goPage(`${productSelected.route}/${productSelected._id}`)}
                        inv={true}
                    />
                }
            </div>
        </div>
    )
}

export default Menu;