import { useSelector } from "react-redux";

export const useUpdtEmail = () => {
    const lang = useSelector(state => state.menu.lang);

    const updtEmail = async (_id, email, password) => {        
        const req = await fetch(`https://api.pro-vide.eu/user/edit-email/${_id}/${lang}`, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password: password, email: email })
        });
        
        const res = await req.json();
        
        if (req.status !== 200) {
            return res.message
        }

        return res
    };

    return updtEmail;
};