import { useState } from "react";
import { useSelector } from "react-redux";

import { useCheckFormData } from "../../hooks/useCheckFormData";

import Input from "../../components/Input";

import logo from './../../assets/svg/contact_icon-contact.svg'
import file_icon from './../../assets/svg/files_icon-input.svg'

import "./style.css"

function FormContact() {
    const checkFormData = useCheckFormData();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [formData, setFormData] = useState({
        name: '',
        company: '',
        city: '',
        country: '',
        phone: '',
        email: '',
        request: ''
    });
    const [alertAnime, setAlertAnime] = useState({
        name: false,
        company: false,
        city: false,
        country: false,
        phone: false,
        email: false,
        request: false
    });

    const request = async (event) => {
        event.preventDefault();

        const fields = [
            'name',
            'company',
            'city',
            'country',
            'phone',
            'email',
            'request'
        ];        

        const dataIsCheck = checkFormData(formData, setAlertAnime, fields);

        if (!dataIsCheck) {            
            setTimeout(() => {
                setAlertAnime({
                    name: false,
                    company: false,
                    city: false,
                    country: false,
                    phone: false,
                    email: false,
                    request: false
                });
            }, 1200)
            return
        }

        const req = await fetch(`https://api.pro-vide.eu/user/contact`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        });
        const res = await req.json();
        
        if (res === true) {
            setFormData({
                name: '',
                company: '',
                city: '',
                country: '',
                phone: '',
                email: '',
                request: ''
            })
            alert('Ok');
            return
        }
        alert('Erreur');
    }

    return (
        <form className='form_contact' onSubmit={(event) => request(event)}>
            <h2><img src={logo} alt="Logo" />{trad.title_form_contact[lang]}</h2>

            <div className="container">
                <Input
                    className="contact"
                    subClass={alertAnime.name}
                    label={trad.label_name[lang] + " :"}
                    type="text"
                    placeholder='John DOE'
                    value={formData}
                    setValue={setFormData}
                    stateLabel="name"
                />

                <Input
                    className="contact"
                    subClass={alertAnime.company}
                    label={trad.label_company[lang] + " :"}
                    type="text"
                    placeholder='John Doe & cie'
                    value={formData}
                    setValue={setFormData}
                    stateLabel="company"
                />

                <Input
                    className="contact"
                    subClass={alertAnime.email}
                    label={trad.label_email[lang] + " :"}
                    type="text"
                    placeholder='john.doe@hotmail.fr'
                    value={formData}
                    setValue={setFormData}
                    stateLabel="email"
                />

                <Input
                    className="contact"
                    subClass={alertAnime.city}
                    label={trad.label_city[lang] + " :"}
                    type="text"
                    placeholder='Paris'
                    value={formData}
                    setValue={setFormData}
                    stateLabel="city"
                />

                <Input
                    className="contact"
                    subClass={alertAnime.country}
                    label={trad.label_country[lang] + " :"}
                    type="text"
                    placeholder='France'
                    value={formData}
                    setValue={setFormData}
                    stateLabel="country"
                />

                <Input
                    className="contact"
                    subClass={alertAnime.phone}
                    label={trad.label_phone[lang] + " :"}
                    type="text"
                    placeholder='02 31 31 14 12'
                    value={formData}
                    setValue={setFormData}
                    stateLabel="phone"
                />

                <div className='input_request'>
                    <label>{trad.label_request_contact[lang]}</label>

                    <textarea
                        type="text"
                        value={formData.request}
                        className={alertAnime.request ? 'alert_field' : ''}
                        placeholder={trad.placeholder_request_contact[lang]}
                        onChange={e => setFormData(prev => ({ ...prev, request: e.target.value }))}
                    />
                </div>

                <div className="input_file">
                    <label>{trad.label_input_file[lang]}</label>

                    <div className="inputFile">
                        <input type="file" />
                        <p><img src={file_icon} alt="Icon" />{trad.placeholder_input_file[lang]}</p>
                    </div>
                </div>
            </div>

            <button type='submit'>{trad.button_form_contact[lang]}</button>
        </form>
    )
}

export default FormContact;