import { useSelector } from "react-redux";

export const useGetServiceList = () => {
    const data = useSelector(state => state.data.services);

    const getServiceList = (_id) => {
        return data.filter(element => element.parentId === _id);
    };

    return getServiceList;
};