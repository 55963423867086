import { useDispatch, useSelector } from "react-redux";
import { set_lang } from "../../redux";
import { useState } from "react";

import "./style.css"

import chevron_icon from './../../assets/svg/chevron_icon-nav-desk.svg'
import flag_fr from './../../assets/svg/flag-fr.svg'
import flag_en from './../../assets/svg/flag-en.svg'
import flag_sp from './../../assets/svg/flag-es.svg'
import flag_de from './../../assets/svg/flag-de.svg'

function BtnLang() {
    const dispatch = useDispatch();
    const [panel, show_panel] = useState(false);
    const lang = useSelector(state => state.menu.lang);

    function update_lang(lang_selected) {
        dispatch(set_lang(lang_selected))
        show_panel(false);
    }

    return !panel ? (
        <button className="btn_selector" onClick={() => show_panel(true)}>
            {lang === 'fr' && <img src={flag_fr} alt="Français" />}
            {lang === 'en' && <img src={flag_en} alt="English" />}
            {lang === 'sp' && <img src={flag_sp} alt="Español" />}
            {lang === 'de' && <img src={flag_de} alt="Deutsch" />}
            <img className='chevron' src={chevron_icon} alt="Icon" />
        </button>
    ) : (
        <div className='panel_of_lang'>
            <button onClick={() => update_lang('fr')}><img src={flag_fr} alt="Français" /></button>
            <button onClick={() => update_lang('en')}><img src={flag_en} alt="English" /></button>
            <button onClick={() => update_lang('sp')}><img src={flag_sp} alt="Español" /></button>
            <button onClick={() => update_lang('de')}><img src={flag_de} alt="Deutsch" /></button>
        </div>
    )
}

export default BtnLang;