import "./style.css"

function BtnCheck({ label, stateLabel, state, setState, className }) {
    const update = () => {
        setState(prev => {
            return {
                ...prev,
                [stateLabel]: !state[stateLabel]
            }
        })
    }

    return (
        <button
            type='button'
            className={`btn_check ${className ? className : ''}`}
            onClick={() => update()}
        >
            <i className="box">{state[stateLabel] && '✓'}</i>
            <p>{label}</p>
            {className && <i className="fas fa-triangle-exclamation"></i>}
        </button>
    )
}

export default BtnCheck;