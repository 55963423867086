import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { end_loading, start_loading } from "../../redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useGetUser } from "../../hooks/useGetUser";

import Input from "../../components/Input";

import "./style.css"

function RequestQuote({ showMdl }) {
    const goPage = useGoPage();
    const getUser = useGetUser();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [formCoordinates, setFormCoordinates] = useState(null);
    const [formSpecificsNeeds, setFormSpecificsNeeds] = useState('');

    const [formAddresses, setFormAddresses] = useState([]);
    const [addressSelected, setAddressSelected] = useState('null');

    const [msgError, setMsgError] = useState('');

    useEffect(() => {
        if (!user || user.token === '') {
            setFormCoordinates(null);
            setFormAddresses([]);
            setFormSpecificsNeeds('');
            return
        }

        const fetchProfile = async () => {
            const profile = await getUser(user);

            if (profile) {
                setFormCoordinates({
                    lastname: profile.lastname || '',
                    firstname: profile.firstname || '',

                    society: profile.society || '',
                    service: profile.service || '',
                    siret: user.siret || '',

                    phoneNumber: profile.phoneNumber || '',
                    cellphoneNumber: profile.cellphoneNumber || '',

                    address_street: profile.address_street || '',
                    address_zip_code: profile.address_zip_code || '',
                    address_city: profile.address_city || '',
                    address_country: profile.address_country || ''
                });
                setFormAddresses(profile.delivery_address);
            }
        }
        fetchProfile();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const requestQuote = async () => {
        if (!formCoordinates) {
            goPage('error');
            return
        }

        if (addressSelected === 'null') {
            setMsgError(trad.error_delivery_address[lang]);
            setTimeout(() => setMsgError(''), 3000);
            return
        }

        const body = {
            ...formCoordinates,
            addressId: addressSelected,
            comments: formSpecificsNeeds
        };

        dispatch(start_loading());

        const req = await fetch(`https://api.pro-vide.eu/user/ask-quote/${user._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        if (req.status !== 200 || !res || !res.public_path) {
            dispatch(end_loading());
            goPage('error');
            return
        }

        const a = document.createElement('a');
        document.body.appendChild(a);

        a.href = res.public_path;
        a.target = '_blank';
        a.click();

        document.body.removeChild(a);
        window.location.reload();
        dispatch(end_loading());
    }

    return formCoordinates && (
        <div className='mdl_request_quote'>
            <div className="content">
                <button onClick={() => showMdl(false)} className='btn_close_mdl'><i className='fas fa-xmark'></i></button>

                <h3>{trad.title_mdl_request_quote[lang]}</h3>

                <div className="form">
                    <h4>{trad.title_form_coordinates[lang]}</h4>
                    <div className="container_inp">
                        <Input
                            className='settings lock'
                            label={trad.label_lastname[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='lastname'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_firstname[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='firstname'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_company[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='society'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_service[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='service'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_siret[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='siret'
                            lock={true}
                        />

                        <i className="invisible">{/*Vide*/}</i>

                        <Input
                            className='settings lock'
                            label={trad.label_cellphone[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='cellphoneNumber'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_phone[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='phoneNumber'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_address[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='address_street'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_city[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='address_city'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_country[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='address_country'
                            lock={true}
                        />

                        <Input
                            className='settings lock'
                            label={trad.label_zipcode[lang] + ' :'}
                            type='text'
                            placeholder={''}
                            value={formCoordinates}
                            setValue={setFormCoordinates}
                            stateLabel='address_zip_code'
                            lock={true}
                        />
                    </div>

                    <h4>{trad.delivery_address[lang]}</h4>
                    <div className="address_selector">
                        <label>{trad.label_delivery_address[lang]}</label>

                        <select value={addressSelected} onChange={(e) => setAddressSelected(e.target.value)}>
                            <option value="null">{trad.address_selector_default[lang]}</option>

                            {formAddresses.length !== 0 && formAddresses.map((address, idx) =>
                                <option
                                    key={idx}
                                    value={address.id}
                                >
                                    {address.name}, {address?.address_street}, {address?.address_city} {address.address_country ? ',' + address.address_country : ''}
                                </option>
                            )}
                        </select>
                    </div>

                    <h4>{trad.specifics_needs[lang]}</h4>
                    <div className='input_request'>
                        <label>{trad.label_specifics_needs[lang]}</label>

                        <textarea
                            type="text"
                            value={formSpecificsNeeds}
                            placeholder={trad.placeholder_request_contact[lang]}
                            onChange={e => setFormSpecificsNeeds(e.target.value)}
                        />
                    </div>

                    {msgError !== '' && <p className='msg_error'>{msgError}</p>}

                    <button
                        className='btn_ask_quote'
                        onClick={() => requestQuote()}
                    >{trad.btn_ask_quote[lang]}</button>
                </div>
            </div>
        </div>
    )
}

export default RequestQuote;