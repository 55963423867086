export const useDownloadFile = () => {
    const downloadFile = async (id) => {
        const req = await fetch(`https://api.pro-vide.eu/public/file/${id}`);
        const res = await req.json();

        const a = document.createElement('a');
        a.href = res.plan;

        a.setAttribute('target', '_blank')
        a.setAttribute('download', '');
        
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
    };

    return downloadFile;
};
