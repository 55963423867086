export const useUpdtUser = () => {

    const updtUser = async (_id, data, password) => {        
        const req = await fetch(`https://api.pro-vide.eu/user/edit-account/${_id}`, {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...data, password: password })
        });
        
        const res = await req.json();
        
        if (req.status !== 200) {
            return res.message
        }

        return res
    };

    return updtUser;
};