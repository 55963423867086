import ReactPlayer from "react-player";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useFormatText } from "../../hooks/useFormatText";
import { useFetchImage } from "../../hooks/useFetchImage";

import Banner from './../../components/Banner';
import SliderServ from "../../components/SliderServ";

import "./style.css"

import icon_contact from './../../assets/svg/contact_icon-product.svg'
import icon_phone from './../../assets/svg/phone_icon-product.svg'
import icon_message from './../../assets/svg/message_icon-product.svg'
import RequestPOR from "../../modals/RequestPOR";

function Service() {
    const { id } = useParams();

    const goPage = useGoPage();
    const formatText = useFormatText();
    const fetchImage = useFetchImage();

    const [images, setImages] = useState([]);
    const [dataPage, setDataPage] = useState(null);
    const [requestSpe, showRequestSpe] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const services = useSelector(state => state.data.services);

    // Récupération de la largeur de l'écran
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Recuperation des données de la page
    useEffect(() => {
        if (!id || !services || services.length === 0) {
            setDataPage(null);
            setImages([]);
            return
        }

        const service = services.find(e => e._id === id);

        if (!service) {
            goPage('error');
        }

        setDataPage(service);

        if (!service.images || service.images.length === 0) {
            setImages([]);
            return
        }

        const fetchImages = async () => {
            let newImages = [];

            for (let imgId of service.images) {
                if (imgId && imgId !== '') {
                    const result = await fetchImage(imgId);
                    newImages.push(result);
                }
            }

            setImages(newImages);
        }
        fetchImages();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, services]);
    

    return dataPage && (
        <div className='page_service'>
            {dataPage.video &&
                <div className="container_video_banner">
                    <div className="player">
                        <ReactPlayer width='100%' height='400px' url={dataPage.video} />
                    </div>
                    <Banner />
                </div>
            }

            <div className="container_slider_descrip">
                <div className="content">
                    <div className="descrip">
                        <h2>{dataPage.name}</h2>
                        <p>{formatText(dataPage.text)}</p>
                        <button onClick={() => showRequestSpe(true)} className='btn_request'>{trad.make_a_request[lang]}</button>
                    </div>

                    <SliderServ images={images} windowWidth={windowWidth} />
                </div>
            </div>

            <div className="contact">
                <p><img src={icon_contact} alt="Icon" />{trad.msg_bottom_contact[lang]}</p>

                <div className="container_btn_contact">
                    <a href="tel:+330255996505" className="btn-phone"><img src={icon_phone} alt="Icon" />+33 2 55 99 65 05</a>
                    <button onClick={() => goPage('contact')} className="btn-email"><img src={icon_message} alt="Icon" />{trad.btn_bottom_contact[lang]}</button>
                </div>
            </div>

            {requestSpe && <RequestPOR elementId={dataPage._id} type='serv' qte='1' showMdl={showRequestSpe} />}
        </div>
    )
}

export default Service;