import { useDispatch, useSelector } from "react-redux";
import { login, start_loading, end_loading } from "../redux";
import { useGoPage } from "./useGoPage";

export const usePostSignup = () => {
    const dispatch = useDispatch();
    const goPage = useGoPage();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const postSignup = async (data, code) => {
        dispatch(start_loading());

        const body = {
            ...data,
            password: data.pw,
            code: code,
            lang: lang
        }


        try {
            const req = await fetch(`https://api.pro-vide.eu/user/signup`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            });

            if (req.status === 400) {
                throw new Error(trad.error_data_invalid[lang]);
            }

            if (req.status === 401) {
                const res = await req.json();
                
                if (res.counter !== undefined) {
                    throw new Error(trad.error_code_invalid[lang] + ', ' + res.counter + trad.msg_counter_code[lang]);
                }
                throw new Error(trad.msg_resend_code[lang] + data.email);
            }

            if (req.status === 404) {
                throw new Error(trad.error_user_not_found[lang]);
            }

            if (req.status === 500) {
                throw new Error(trad.error_server[lang]);
            }

            const res = await req.json();

            if (res && res._id) {
                sessionStorage.setItem('lastname', res.lastname);
                sessionStorage.setItem('firstname', res.firstname);
                sessionStorage.setItem('password', data.pw);
                sessionStorage.setItem('token', res.token);
                sessionStorage.setItem('_id', res._id);

                dispatch(login({
                    _id: res._id,
                    token: res.token,
                    password: data.pw,
                    firstname: res.firstname,
                    lastname: res.lastname
                }));

                goPage('');
            }
            return '';
        }
        catch (error) {
            return error.message;
        }
        finally {
            dispatch(end_loading());
        }
    };

    return postSignup;
};