import { useDispatch, useSelector } from "react-redux";
import { start_loading, end_loading } from "../redux";

export const useResendCode = () => {
    const dispatch = useDispatch();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const resendCode = async (email, type) => {
        dispatch(start_loading());

        try {
            const req = await fetch(`https://api.pro-vide.eu/user/send-new-code/${lang}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    type: type
                })
            });

            if (req.status === 400) {
                throw new Error(trad.error_email_invalid[lang]);
            }

            if (req.status === 404) {
                throw new Error(trad.error_user_not_found[lang]);
            }

            if (req.status === 500) {
                throw new Error(trad.error_send_code[lang]);
            }

            return await req.json();
        }
        catch (error) {
            return error.message;
        }
        finally {
            dispatch(end_loading());
        }
    };

    return resendCode;
};