import { useSelector } from "react-redux";

export const useConfUpdtEmail = () => {
    const lang = useSelector(state => state.menu.lang);

    const confUpdtEmail = async (_id, token, code) => {        
        const req = await fetch(`https://api.pro-vide.eu/user/edit-email-conf/${_id}/${lang}`, {
            method: 'put',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ code: code })
        });
        
        const res = await req.json();
        
        if (req.status !== 200) {
            return res.message
        }

        return res
    };

    return confUpdtEmail;
};