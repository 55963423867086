import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useLogout } from "../../hooks/useLogout";
import { useFormatPrice } from "../../hooks/useFormatPrice";
import { useDownloadFile } from "../../hooks/useDownloadFile";

import RequestPOR from "../../modals/RequestPOR";

import "./style.css"

import icon_download from './../../assets/svg/download-icon.svg';
import icon_request from "./../../assets/svg/icon_por.svg";
import icon_cart from "./../../assets/svg/icon_cart_white.svg";
import icon_add_cart from "./../../assets/svg/added_cart.svg";
import icon_like from "./../../assets/svg/icon_like.svg";

function TableRefMob({ data }) {
    const goPage = useGoPage();
    const kill_session = useLogout();
    const formatPrice = useFormatPrice();
    const downloadFile = useDownloadFile();

    const _id = useSelector(state => state.user._id);
    const token = useSelector(state => state.user.token);

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [tableFilters, setTableFilters] = useState({});
    const [filters, setFilters] = useState({});

    const [tableData, setTableData] = useState([]);
    const [tableInput, setTableInput] = useState([]);
    const [tablePage, setTablePage] = useState({ page: 0, max: 0 });

    const [requestSpe, showRequestSpe] = useState(false);
    const [index, setIndex] = useState(null);

    const [msgConf, setMsgConf] = useState(null);

    useEffect(() => {
        if (!data.references) {
            setTableData([]);
            return
        }

        const fetchTableFilters = async () => {
            const req = await fetch(`https://api.pro-vide.eu/public/references_filters/${data._id}`);

            if (req.status !== 200) {
                goPage('error');
                return
            }

            const res = await req.json();
            setTableFilters(res);

            let newFilters = { ...res };

            Object.keys(newFilters).forEach(key => {
                newFilters[key] = 'all';
            });

            setFilters(newFilters);
        }
        fetchTableFilters();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (!Object.keys(filters).length === 0) {
            setTableInput([]);
            setTableData([]);
            return
        }

        const filtersReq = [];

        Object.keys(filters).forEach(key => {
            if (filters[key] !== 'all') {
                filtersReq.push({ field: key, value: filters[key] })
            }
        });

        const fetchTableRef = async () => {
            const req = await fetch(`https://api.pro-vide.eu/public/${token && token !== '' ? '' : 'p-'}references/${data._id}${token && token !== '' ? `/${_id}` : ''}`, {
                method: 'PUT',
                headers: token && token !== '' ? { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } : { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    page: tablePage.page,
                    filters: filtersReq
                })
            });

            const res = await req.json();

            if (req.status === 401) {
                kill_session();
                goPage('login');
                return
            }

            if (req.status !== 200) {
                goPage('error');
                return
            }

            const newTableRef = [];
            const newTableInp = [];

            for (let ref of res.references) {
                newTableRef.push({ _id: ref._id, ...ref.table });
                newTableInp.push('1');
            }

            setTableData(newTableRef);
            setTableInput(newTableInp);

            const totalPages = Math.ceil(res.total / 10);

            if (totalPages !== tablePage.max) {
                setTablePage({ ...tablePage, max: totalPages - 1 });
            }
        }
        fetchTableRef();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tablePage.page, filters, data])

    function setInput(e, id) {
        const value = e.target.value;
        let newTableInput = [...tableInput];

        if (!/^\d+$/.test(value) || parseInt(value) > 10000) {
            return
        }

        newTableInput[id] = value;

        setTableInput(newTableInput);
    }

    function showBtnBuy(ref) {
        return ref.price !== '' && ref.price !== '0.00000'
    }

    function updateFilters(filter, e) {
        const value = e.target.value;
        const newFilters = { ...filters };

        newFilters[filter] = value;
        setFilters(newFilters);
    }

    const renderPagination = () => {
        let buttons = [];

        function page(i) {
            window.scrollTo(0, 1200);
            setTablePage({ ...tablePage, page: i });
        }

        if (tablePage.page > 2) {
            buttons.push(
                <button
                    key="first"
                    onClick={() => page(0)}
                >1</button>
            );

            if (tablePage.page > 3) {
                buttons.push(<span key="first-dots">...</span>);
            }
        }

        for (let i = Math.max(0, tablePage.page - 2); i <= Math.min(tablePage.max, tablePage.page + 2); i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => page(i)}
                    className={i === tablePage.page ? 'actif' : ''}
                >
                    {i + 1}
                </button>
            );
        }

        if (tablePage.page < tablePage.max - 3) {
            if (tablePage.page < tablePage.max - 4) {
                buttons.push(<span key="last-dots">...</span>);
            }

            buttons.push(
                <button
                    key="last"
                    onClick={() => page(tablePage.max)}
                >
                    {tablePage.max}
                </button>
            );
        }

        return buttons;
    };

    const addToCart = async (ref, idx) => {
        const req = await fetch(`https://api.pro-vide.eu/user/add-cart/${_id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: data._id,
                refId: ref._id,
                qte: parseInt(tableInput[idx])
            })
        });
        const res = await req.json();

        if (req.status !== 200 || res !== true) {
            setMsgConf({ status: 'error', message: res.message });
            setTimeout(() => setMsgConf(null), 2500);
            return
        }

        setMsgConf({ status: 'conf', message: ref.References });
        setTimeout(() => setMsgConf(null), 2500);
    }

    const speRequest = (idx) => {
        setIndex(idx);
        showRequestSpe(true);
    }


    return data && data.references && (
        <section className='sect_table_ref_mobile'>
            <div className="table_ref">

                {Object.keys(tableFilters).length !== 0 &&
                    <div className="table_row filters">
                        <div className="row">
                            <p className="label">{trad.filters[lang]}</p>
                        </div>

                        {data.references.head.map((filter, idx) => idx > 1 &&
                            <div key={idx} className="row">
                                <p className="label">{filter}</p>

                                <select onClick={(e) => updateFilters(filter, e)}>
                                    <option value='all'>All</option>
                                    {tableFilters[filter].map((e, id) => <option key={id} value={e}>{e}</option>)}
                                </select>
                            </div>
                        )}
                    </div>
                }

                {tableData && tableData.length !== 0 && tableData.map((ref, idx) =>
                    <div key={idx} className='table_row'>

                        {/* Reference */}
                        <div className="row">
                            <p className="label">{data.references.head[0]}</p>
                            <p className="value">{ref[data.references.head[0]]}</p>
                        </div>

                        {/* 3D-plan */}
                        <div className="row">
                            <p className="label">{data.references.head[1]}</p>

                            {ref['3D-plan'] !== 'lock' ?
                                <button className='btn_download'>
                                    {ref['3D-plan'] !== '' && <img src={icon_download} alt="Icon" />}
                                    {ref['3D-plan'] !== '' && <p onClick={() => downloadFile(ref['3D-plan'])}>Download</p>}
                                </button>
                                :
                                <button className='btn_go_to_login' onClick={() => goPage('login')}><p><i className="fas fa-eye-slash"></i> login</p></button>
                            }
                        </div>

                        {/* Others */}
                        {data.references.head.map((label, id) => id > 1 &&
                            <div key={id} className="row">
                                <p className="label">{label}</p>
                                <p className="value">{ref[label]}</p>
                            </div>
                        )}

                        {/* Price */}
                        <div className="row">
                            <p className="label">Price</p>

                            {token !== '' ?
                                <p className="value">{tableData[idx].price !== '' && tableData[idx].price !== '0.00000' ? formatPrice(tableData[idx].price) + '€' : ''}</p>
                                :
                                <button className='btn_go_to_login' onClick={() => goPage('login')}><p><i className="fas fa-eye-slash"></i> login</p></button>
                            }
                        </div>

                        {/* Quantity */}
                        <div className="row row_last">
                            <p className="label">Quantity</p>
                            <input
                                type="number"
                                max={999}
                                min={1}

                                value={tableInput[idx]}
                                onChange={(e) => setInput(e, idx)}
                            />
                        </div>

                        {showBtnBuy(ref) && token !== '' ?
                            <button
                                className='btn_add_to_cart'
                                onClick={() => addToCart(ref, idx)}
                            >
                                <img src={icon_cart} alt="Add to cart" />Add to cart
                            </button>
                            :
                            <button
                                className='btn_request'
                                onClick={() => speRequest(idx)}
                            >
                                <img src={icon_request} alt="Make a request" />Request
                            </button>
                        }
                    </div>
                )}
            </div>

            <div className="pagination">
                {renderPagination()}
            </div>

            {msgConf && msgConf.status === 'error' &&
                <div className="msg_error">
                    <div className='content'>
                        <p>{msgConf.message}</p>
                    </div>
                </div>
            }

            {msgConf && msgConf.status === 'conf' &&
                <div className='msg_conf'>
                    <h4>
                        {trad.title_msg_add_to_cart[lang]}
                        <img src={icon_like} alt='icon' />
                    </h4>

                    <p><span>"{msgConf.message}"</span>{trad.msg_add_to_cart[lang]}</p>

                    <img className='back_icon' src={icon_add_cart} alt='icon' />
                </div>
            }

            {requestSpe && index !== null && <RequestPOR elementId={data._id} type='prod' refId={tableData[index]._id} qte={tableInput[index]} showMdl={showRequestSpe} setSelected={setIndex} mob={true} />}
        </section>
    )
}

export default TableRefMob;