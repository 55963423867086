import { useSelector } from "react-redux";

import "./style.css"

function Pp() {
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    return (
        <div className='page_privacy_policy'>
            <h2>{trad.title_privacy_policy[lang]}</h2>
            
            <ul>
                {trad.data_privacy_policy.map((data, idx) =>
                    <li key={idx}>
                        <h3>{idx + 1}. {data.title[lang]}</h3>
                        <p>{data.text[lang]}</p>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Pp;