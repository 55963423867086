import { useState } from "react";
import { useSelector } from "react-redux";

import { useUpdtUser } from "../../hooks/useUpdtUser";
import { useUpdtPassword } from "../../hooks/useUpdtPassword";

import { useUpdtEmail } from "../../hooks/useUpdtEmail";
import { useConfUpdtEmail } from "../../hooks/useConfUpdtEmail";

import InputCode from "../../components/InputCode";

import "./style.css"

function VerifPassword({ id, setup, setSetup }) {
    const updtUser = useUpdtUser();
    const updtPassword = useUpdtPassword();

    const updtEmail = useUpdtEmail();
    const confUpdtEmail = useConfUpdtEmail();

    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    const [isUpdt, setIsUpdt] = useState('');

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const token = useSelector(state => state.user.token);

    const updtProfile = async () => {
        let response = null;

        if (setup.type === 'all') {
            response = await updtUser(id, setup.data, password);
        }

        if (setup.type === 'password') {
            response = await updtPassword(id, setup.data, password);
        }

        if (setup.type === 'email') {
            response = await updtEmail(id, setup.data, password);
            

            if (response === true) {
                setIsUpdt('wait');
                return
            }
        }

        if (response !== true) {
            setIsUpdt(response + ' !');

            setTimeout(() => {
                setIsUpdt('');
            }, 3000)
            return
        }

        setIsUpdt(trad.msg_confirm_user_update[lang]);

        setTimeout(() => {
            window.location.reload();
        }, 3000)
    }

    const confUpdateEmail = async () => {
        const response = await confUpdtEmail(id, token, code);
        
        if (response !== true) {
            setIsUpdt(response + ' !');

            setTimeout(() => {
                setIsUpdt('wait');
            }, 3000)
            return
        }

        setIsUpdt(trad.msg_confirm_user_update[lang]);
        
        setTimeout(() => {
            window.location.reload();
        }, 3000)
    }

    return id && setup && (
        <div className='mdl_verif_pw'>
            {isUpdt === '' &&
                <div className="content">
                    <button className='btn_close'>
                        <i
                            className="fas fa-xmark"
                            onClick={() => setSetup({ data: null, type: '' })}
                        ></i>
                    </button>

                    <span>{trad.confirmation[lang]}</span>
                    <p>{trad.confirm_with_password[lang]}</p>

                    <input
                        className='inp_pw_conf'
                        type="password"
                        placeholder='********************'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <button
                        className='btn_conf_updt'
                        onClick={() => updtProfile()}
                    >{trad.btn_valid[lang]}</button>
                </div>
            }

            {isUpdt === 'wait' &&
                <div className="content">
                    <span>{trad.confirmation[lang]}</span>
                    <p>{trad.text_mdl_update_email[lang]}</p>

                    <InputCode value={code} setValue={setCode} />

                    <button
                        className='btn_conf_updt'
                        onClick={() => confUpdateEmail()}
                    >{trad.btn_valid[lang]}</button>
                </div>
            }

            {isUpdt !== '' && isUpdt !== 'wait' &&
                <div className="content">
                    <p className='msgConf'>{isUpdt}</p>
                </div>
            }
        </div>
    )
}

export default VerifPassword;