export const useCheckFormData = () => {
    const checkFormData = (formData, setAlertAnime, fields) => {
        let newAlert = {};

        let response = true;

        for (let field of fields) {
            if (formData[field] === '' || typeof formData[field] !== 'string') {
                newAlert[field] = true;
                response = false;
            }
            else {
                newAlert[field] = false
            }
        }

        setAlertAnime(newAlert);
        return response
    };

    return checkFormData;
};
