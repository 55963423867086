import { useDispatch } from "react-redux";

import { end_loading, start_loading } from "../redux";

export const useFetchImage = () => {
    const dispatch = useDispatch();

    const fetchImage = async (id) => {
        dispatch(start_loading());

        try {
            const req = await fetch(`https://api.pro-vide.eu/public/image/${id}`);

            if (req.status !== 200) {
                dispatch(end_loading());
                throw new Error(null);
            }
            
            const res = await req.json();
            dispatch(end_loading());
            
            return res    
        } 
        catch (error) {
            return error.message;            
        }
    };
    return fetchImage;
};
