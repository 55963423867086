import { useDispatch } from "react-redux";
import { useLogout } from "./useLogout";
import { end_loading, start_loading } from "../redux";

import { useGoPage } from "./useGoPage";

export const useGetUser = () => {
    const goPage = useGoPage();
    const dispatch = useDispatch();
    const kill_session = useLogout();

    const getUser = async (user) => {   
        dispatch(start_loading());     
        const req = await fetch(`https://api.pro-vide.eu/user/account/${user._id}`, {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${user.token}` }
        });

        if (req.status === 401) {
            kill_session();
            goPage('login');
            dispatch(end_loading());
            
            return null
        }
        
        if (req.status !== 200) {
            kill_session();
            goPage('error');
            dispatch(end_loading());

            return null
        }

        const res = await req.json();
        dispatch(end_loading());
        return res
    };

    return getUser;
};