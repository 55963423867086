import { useState } from "react";

import "./style.css"

function SliderServ({ images, windowWidth }) {
    const [counter, setCounter] = useState(0);

    const prev = () => {
        setCounter(counter === 0 ? parseInt(images.length) - 1 : counter - 1);
    }

    const next = () => {
        setCounter(counter === parseInt(images.length) - 1 ? 0 : counter + 1);        
    }

    return images.length !== 0 && (
        <div className='slider_service'>
            {images.length !== 1 && <button onClick={() => prev()} className='arrow arrow_left'>{'<'}</button>}

            <div className="border_view">
                <div className="view">
                    <img src={images[counter][`${windowWidth < 700 ? 'small_' : ''}image`]} alt='Illustration' />
                </div>
            </div>

            {images.length !== 1 && <button onClick={() => next()} className='arrow arrow_right'>{'>'}</button>}

            {images.length !== 1 &&
                <div className="dots">
                    {images.map((e, idx) =>
                        <i key={idx} className={idx === counter ? 'dot dot_actif' : 'dot'}></i>
                    )}
                </div>
            }
        </div>
    )
}

export default SliderServ;