import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";

import "./style.css"

function Achievement({ data, setAchievSelected }) {
    const fetchImage = useFetchImage();
    const formatText = useFormatText();

    const [images, setImages] = useState([]);
    const [counter, setCounter] = useState(0);

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    // Recuperation des images
    useEffect(() => {
        if (!data || !data.images || data.images.length === 0) {
            setImages([]);
            return
        }

        const fetchImages = async () => {
            let newImages = [];

            for (let imgId of data.images) {
                if (imgId && imgId !== '') {
                    const result = await fetchImage(imgId);
                    newImages.push(result);
                }
            }

            setImages(newImages);
        }
        fetchImages();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const previousSlide = () => {
        setCounter(counter === 0 ? parseInt(images.length) - 1 : counter - 1);
    }

    const nextSlide = () => {
        setCounter(counter === parseInt(images.length) - 1 ? 0 : counter + 1);
    }

    return (
        <div className='mdl_achievement'>
            <div className="content">
                <div className="slider_achievement">
                    {images && images.length > 1 && <i onClick={() => previousSlide()} className="arrow arrow_left">{'<'}</i>}
                    {images && images.length !== 0 && <img src={images[counter].image} alt="Illustration" />}
                    {images && images.length > 1 && <i onClick={() => nextSlide()} className="arrow arrow_right">{'>'}</i>}
                </div>

                <div className="descrip">
                    <h2>{data.name}</h2>
                    <p>{formatText(data.text)}</p>
                </div>

                <button onClick={() => setAchievSelected(null)}>{trad.close[lang]}</button>
            </div>
        </div>
    )
}

export default Achievement;