import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { close } from "../redux";


export const useGoPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const goPage = (route) => {
        window.scrollTo(0, 0);
        dispatch(close());
        
        navigate(`/${route}`);
    };

    return goPage;
};
