import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";

import Banner from "../../components/Banner";
import Slider from "../../components/Slider";
import MainMenu from "../../components/MainMenu";

import "./style.css"

function Home() {
    const fetchImage = useFetchImage();

    const [images, setImages] = useState([]);
    const [dataPage, setDataPage] = useState([]);
    const [childRoute, setChildRoute] = useState('error');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const categories = useSelector(state => state.data.categories);

    // Récupération de la largeur de l'écran
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Récupération des données
    useEffect(() => {
        const newDataPage = categories.filter(cat => !cat.parentId && cat.type === 'prod');
        setChildRoute('category');
        setDataPage(newDataPage);
    }, [categories])

    // Récupération des images
    useEffect(() => {
        if (!dataPage || dataPage.length === 0) {
            setImages([]);
            return
        }

        const fetchImagesData = async () => {
            let newImages = [];

            for (let category of dataPage) {
                if (!category.image || category.image === '') continue
                const categoryImg = await fetchImage(category.image);
                newImages.push(categoryImg);
            }


            setImages(newImages);
        }
        fetchImagesData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPage])

    return dataPage.length !== 0 && images.length !== 0 && (
        <div className='page page_home'>
            <div className="container_slider_banner">
                <Slider type='category' data={dataPage} images={images} childRoute='category' windowWidth={windowWidth} />
                <Banner windowWidth={windowWidth} />
            </div>

            <MainMenu className='home' data={dataPage} images={images} childRoute={childRoute} windowWidth={windowWidth} />
        </div>
    )
}

export default Home;