import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Achievement from "../../modals/Achievement";

import "./style.css"

import illustration from './../../assets/usinage_image.png';
import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";

function Achievements() {
    const filtersRef = useRef(null);
    
    const navigate = useNavigate();
    const fetchImage = useFetchImage();
    const formatText = useFormatText();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const categories = useSelector(state => state.data.categories);
    const achievements = useSelector(state => state.data.achievements);

    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);

    const [filters, setFilters] = useState('all');
    const [achievlSelected, setAchievSelected] = useState(null);

    // Filtrage des catégories et calcule du nb de pages
    useEffect(() => {
        let newData = [];
        
        if(!achievements || achievements.length === 0) {
            setData(newData);
            setMaxPage(0);
            return
        }

        let dataPage = [...achievements];

        if (filters !== 'all') {
            dataPage = achievements.filter(e => e.parentId === filters);
        }

        const multiplicator = Math.floor(parseInt(dataPage.length) / 20);
        setMaxPage(multiplicator);

        if (multiplicator === 0) {
            setData([dataPage]);
            return
        }
        
        for (let i = 0; i < multiplicator; i++) {
            let newPage = [];

            for (let n = 0; n < 20; n++) {
                newPage.push(dataPage[(i * 20) + n]);
            }

            newData.push(newPage);
        }

        setData(newData);
    }, [achievements, filters])

    // Recuperation des images de la page
    useEffect(() => {
        if (!data || data.length === 0) {
            setImages([]);
            return
        }

        const fetchImages = async () => {
            let newImages = [];

            for (let achiev of data[page]) {
                if (achiev.images[0] && achiev.images[0] !== '') {
                    const result = await fetchImage(achiev.images[0]);
                    newImages.push(result);
                }
            }

            setImages(newImages);
        };
        fetchImages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, page])

    // Rendu pagination
    const renderPagination = () => {
        let buttons = [];

        if (page > 2) {
            buttons.push(<button key="first" onClick={() => handlePageChange(0)}>1</button>);
            if (page > 3) {
                buttons.push(<span key="first-dots">...</span>);
            }
        }

        for (let i = Math.max(0, page - 2); i <= Math.min(maxPage - 1, page + 2); i++) {
            buttons.push(
                <button key={i} onClick={() => handlePageChange(i)} className={i === page ? 'actif' : ''}>
                    {i + 1}
                </button>
            );
        }

        if (page < maxPage - 3) {
            if (page < maxPage - 4) {
                buttons.push(<span key="last-dots">...</span>);
            }
            buttons.push(<button key="last" onClick={() => handlePageChange(maxPage - 1)}>{maxPage}</button>);
        }

        return buttons;
    };

    // Changement de page
    const handlePageChange = (newPage) => {
        setPage(newPage);
        if (filtersRef.current) {
            filtersRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Voir une realisation
    const showAchievement = (id) => {
        const real = achievements.find(e => e._id === id);
        if (!real) {
            navigate('/error');
            return
        }

        setAchievSelected(real);
    }

    return (
        <div className='page_achievement'>
            {achievlSelected && <Achievement data={achievlSelected} setAchievSelected={setAchievSelected} />}

            <section className='presentation'>
                <img src={illustration} alt="illustration" />

                <div className="descrip">
                    <h2>{trad.title_achievement[lang]}</h2>
                    <p>{formatText(trad.descrip_achievement[lang])}</p>
                </div>
            </section>

            <section className='sect-achievement' ref={filtersRef}>
                <div className="filters">
                    <span>{trad.filters[lang]} :</span>

                    <button className={filters === 'all' ? 'actif' : ''} onClick={() => setFilters('all')}>{trad.all[lang]}</button>

                    {categories && categories.map(cat => cat.type === 'prod' && !cat.parentId && (
                        <button
                            key={cat._id}
                            onClick={() => setFilters(cat._id)}
                            className={filters === cat._id ? 'actif' : ''}
                        >{cat.name}</button>
                    ))}
                </div>

                <ul className={`main_achievement`}>
                    {data[page] && data[page].length !== 0 && data[page].map((achievement, idx) => (
                        <li
                            key={idx}
                            onClick={() => showAchievement(achievement._id)}
                        >
                            <div className="container-img">
                                {images && images.length !== 0 && <img src={images[idx].image} alt="Illustration" />}
                            </div>

                            <p>{achievement.name}</p>
                        </li>
                    ))}
                </ul>

                <div className="pagination">
                    {renderPagination()}
                </div>
            </section>
        </div>
    )
}

export default Achievements;