import { useState } from "react"
import "./style.css"

function Input({ className, subClass, label, type, placeholder, value, setValue, stateLabel, lock }) {
    const [showPassword, setShowPassword] = useState('password');

    const update = (e) => {
        if (stateLabel === '') {
            setValue(e.target.value)
            return
        }

        setValue(prev => {
            return {
                ...prev,
                [stateLabel]: e.target.value
            }
        })
    }

    return (
        <div className={`input_${className}`}>
            {label && label !== '' && <label>{label}</label>}

            <input 
                type={type === 'password' ? showPassword : type}
                placeholder={placeholder}

                value={stateLabel === '' ? value : value[stateLabel] || ''}
                onChange={(e) => update(e)}
                className={subClass ? 'alert_field' : ''}
                readOnly={lock ? lock : false}
            />

            {type === 'password' &&
                <button type='button' onClick={() => setShowPassword(prev => prev === 'text' ? 'password' : 'text')}>
                    {showPassword === 'text' && <i className="fas fa-eye"></i>}
                    {showPassword === 'password' && <i className="fas fa-eye-slash"></i>}
                </button>
            }
        </div>
    )
}

export default Input;