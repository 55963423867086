import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { end_loading, start_loading } from "../../redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useGetUser } from "../../hooks/useGetUser";
import { useFormatText } from "../../hooks/useFormatText";
import { useCheckFormData } from "../../hooks/useCheckFormData";

import Input from "../../components/Input";

import "./style.css"

function RequestPOR({ elementId, type, refId, qte, text, showMdl, setSelected, mob }) {
    const goPage = useGoPage();
    const getUser = useGetUser();
    const dispatch = useDispatch();
    const formatText = useFormatText();
    const checkFormData = useCheckFormData();

    const user = useSelector(state => state.user);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [formCoordinates, setFormCoordinates] = useState({
        lastname: '',
        firstname: '',
        society: '',
        service: '',
        siret: '',
        email: '',
        phoneNumber: '',
        cellphoneNumber: '',
        address_street: '',
        address_zip_code: '',
        address_city: '',
        address_country: ''
    });
    const [alertFields, setAlertFields] = useState({
        lastname: false,
        firstname: false,
        society: false,
        email: false,
        cellphoneNumber: false,
        address_street: false,
        address_zip_code: false,
        address_city: false,
        address_country: false
    });
    const [formSpecificsNeeds, setFormSpecificsNeeds] = useState(text || '');
    const [quantity, setQuantity] = useState(qte);

    const [msgError, setMsgError] = useState('');
    const [msgConf, setMsgConf] = useState('');

    useEffect(() => {
        if (user._id === '' || user.token === '') {
            setFormCoordinates({
                lastname: '',
                firstname: '',
                society: '',
                service: '',
                siret: '',
                phoneNumber: '',
                cellphoneNumber: '',
                address_street: '',
                address_zip_code: '',
                address_city: '',
                address_country: ''
            });
            setFormSpecificsNeeds('');
            return
        }

        const fetchProfile = async () => {
            const profile = await getUser(user);

            if (profile) {
                setFormCoordinates({
                    lastname: profile.lastname || '',
                    firstname: profile.firstname || '',

                    society: profile.society || '',
                    service: profile.service || '',
                    siret: user.siret || '',

                    phoneNumber: profile.phoneNumber || '',
                    cellphoneNumber: profile.cellphoneNumber || '',

                    address_street: profile.address_street || '',
                    address_zip_code: profile.address_zip_code || '',
                    address_city: profile.address_city || '',
                    address_country: profile.address_country || ''
                });
            }
        }
        fetchProfile();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const closeMdl = () => {
        showMdl(false);

        if (setSelected) {
            setSelected(null);
        }
    }

    const speRequest = async () => {
        if (!formCoordinates) {
            goPage('error');
            return
        }

        if (formSpecificsNeeds === '') {
            setMsgError(trad.msg_error_spe_need_require[lang]);
            setTimeout(() => setMsgError(''), 5000);
            return
        }

        if (user._id === '') {
            const fields = [
                'lastname',
                'firstname',
                'society',
                'email',
                'cellphoneNumber',
                'address_street',
                'address_city',
                'address_country',
                'address_zip_code',
            ];

            const check = checkFormData(formCoordinates, setAlertFields, fields);

            if (!check) {
                setTimeout(() => setAlertFields({
                    lastname: false,
                    firstname: false,
                    society: false,
                    email: false,
                    cellphoneNumber: false,
                    address_street: false,
                    address_city: false,
                    address_country: false,
                    address_zip_code: false
                }), 2000)
                return
            }
        }

        dispatch(start_loading());

        const body = user._id === '' ? {
            ...formCoordinates,
            request: formSpecificsNeeds,
            qte: quantity,
            elementId,
            type,
        } : {
            request: formSpecificsNeeds,
            qte: quantity,
            elementId,
            type,
        }

        if (refId) {
            body.refId = refId;
        }

        const req = await fetch(`https://api.pro-vide.eu/user/request${user._id !== '' ? '-user' : ''}${user._id !== '' ? `/${user._id}/` : '/'}${lang}`, {
            method: 'POST',
            headers: user._id !== '' ? {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            } : { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        if (req.status !== 200 || res !== true) {
            dispatch(end_loading());
            setMsgError(res.message);
            setTimeout(() => setMsgError(''), 5000);
            return
        }

        dispatch(end_loading());

        setMsgConf(trad.msg_conf_mdl_request_por[lang]);
        setTimeout(() => {
            setMsgConf('');
            closeMdl();

            if (text || text === '') {
                window.location.reload();
            }
        }, 5000);
    }


    return formCoordinates && (
        <div className='mdl_request_por'>
            {msgConf === '' &&
                <div className="content-mdl">
                    <button onClick={() => closeMdl()} className='btn_close_mdl'><i className='fas fa-xmark'></i></button>

                    <h3>{trad.title_mdl_request_por[lang]}</h3>

                    <div className="form">
                        <h4>{trad.title_form_coordinates[lang]}</h4>
                        <div className="container_inp">
                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.lastname}
                                label={trad.label_lastname[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_lastname[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='lastname'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.firstname}
                                label={trad.label_firstname[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_firstname[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='firstname'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.society}
                                label={trad.label_company[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_company[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='society'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                label={trad.label_service[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_service[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='service'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                label={trad.label_siret[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_siret[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='siret'
                                lock={user._id !== ''}
                            />

                            {!mob && <Input
                                className={user._id !== '' ? 'settings lock invisible' : 'settings'}
                                subClass={alertFields.email}
                                label={trad.label_email[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_email[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='email'
                                lock={user._id !== ''}
                            />}

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.cellphoneNumber}
                                label={trad.label_cellphone[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_cellphone[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='cellphoneNumber'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                label={trad.label_phone[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_phone[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='phoneNumber'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.address_street}
                                label={trad.label_address[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_address[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='address_street'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.address_city}
                                label={trad.label_city[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_city[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='address_city'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.address_country}
                                label={trad.label_country[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_country[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='address_country'
                                lock={user._id !== ''}
                            />

                            <Input
                                className={user._id !== '' ? 'settings lock' : 'settings'}
                                subClass={alertFields.address_zip_code}
                                label={trad.label_zipcode[lang] + ' :'}
                                type='text'
                                placeholder={user._id === '' ? trad.placeholder_inp_zipcode[lang] : ''}
                                value={formCoordinates}
                                setValue={setFormCoordinates}
                                stateLabel='address_zip_code'
                                lock={user._id !== ''}
                            />
                        </div>

                        <h4>{trad.specifics_needs[lang]}</h4>
                        <div className='input_request'>
                            <label>{trad.label_specifics_needs[lang]}</label>

                            <textarea
                                type="text"
                                value={formSpecificsNeeds}
                                placeholder={trad.placeholder_request_contact[lang]}
                                onChange={e => setFormSpecificsNeeds(e.target.value)}
                            />
                        </div>

                        {type === 'prod' && (text || text === '') &&
                            <div className='input_quantity'>
                                <label>{trad.label_specifics_qte[lang]}</label>
                                <input
                                    min={1}
                                    type="number"
                                    value={quantity}
                                    className='input_number'

                                    onChange={(e) => {
                                        if (!/^\d+$/.test(e.target.value) || parseInt(e.target.value) > 10000) {
                                            return
                                        }
                                        setQuantity(e.target.value)
                                    }}
                                />
                            </div>
                        }

                        <p className={msgError !== '' ? 'msg-error' : 'msg-error inv'}>{msgError}</p>

                        <button
                            className='btn_ask_quote'
                            onClick={() => speRequest()}
                        >{trad.make_a_request[lang]}</button>
                    </div>
                </div>
            }

            {msgConf !== '' &&
                <div className="content-mdl">
                    {!mob && <button onClick={() => closeMdl()} className='btn_close_mdl'><i className='fas fa-xmark'></i></button>}
                    <p className='msg-conf'>{formatText(msgConf)}</p>
                </div>
            }
        </div>
    )
}

export default RequestPOR;