import { useDispatch, useSelector } from "react-redux";
import { login, start_loading, end_loading } from "../redux";
import { useGoPage } from "./useGoPage";

export const usePostLogin = () => {
    const dispatch = useDispatch();
    const goPage = useGoPage();
    
    const trad = useSelector((state) => state.trad);
    const lang = useSelector((state) => state.menu.lang);

    const postLogin = async (data) => {
        dispatch(start_loading());

        try {
            const req = await fetch(`https://api.pro-vide.eu/user/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: data.email,
                    password: data.pw,
                }),
            });

            if (req.status === 500) {
                throw new Error(trad.error_server[lang]);
            }

            if (req.status === 400) {
                throw new Error(trad.error_email_or_pw[lang]);
            }

            if (req.status === 404) {
                throw new Error(trad.error_user_not_found[lang]);
            }

            if (req.status === 401) {
                throw new Error(trad.error_pw_invalid[lang]);
            }

            const res = await req.json();

            if (res && res._id) {
                if (data.save) {
                    localStorage.setItem("lastname", res.lastname);
                    localStorage.setItem("firstname", res.firstname);
                    localStorage.setItem("password", data.password);
                    localStorage.setItem("token", res.token);
                    localStorage.setItem("_id", res._id);
                }

                sessionStorage.setItem("lastname", res.lastname);
                sessionStorage.setItem("firstname", res.firstname);
                sessionStorage.setItem("password", data.password);
                sessionStorage.setItem("token", res.token);
                sessionStorage.setItem("_id", res._id);

                dispatch(
                    login({
                        _id: res._id,
                        token: res.token,
                        password: data.password,
                        firstname: res.firstname,
                        lastname: res.lastname,
                    })
                );

                goPage('');
            }

            return '';
        } 
        catch (error) {
            return error.message;
        } 
        finally {
            dispatch(end_loading());
        }
    };

    return postLogin;
};