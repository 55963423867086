import { useSelector } from "react-redux";
import { useGoPage } from "./../../hooks/useGoPage";

import "./style.css"

import logo_pro_vide from './../../assets/logo_pro-vide.png'

function BtnHome() {
    const goPage = useGoPage();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    return (
        <button className="btn_home" onClick={() => goPage('')} >
            <img src={logo_pro_vide} alt="Logo" />
            <h1>Pro-vide<span>{trad.sub_title_website[lang]}</span></h1>
        </button>
    )
}

export default BtnHome;