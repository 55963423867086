import { useSelector } from "react-redux";

export const useGetSubCategoryList = () => {
    const data = useSelector(state => state.data.categories);

    const getSubCategoryList = (type, _id) => {
        return data.filter(element => element.type === type && element.parentId === _id);
    };

    return getSubCategoryList;
};