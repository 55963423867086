import { useSelector } from 'react-redux';

import background_img from './../../assets/space_image.webp';
import logo_white from './../../assets/logo_pro-vide_white.png';

import "./style.css"

function Banner() {
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    return (
        <div className='banner'>
            <i className='hover'></i>
            <img className='background' src={background_img} alt="Background" />

            <img className='logo' src={logo_white} alt="Logo" />
            <p className='title'>PRO-VIDE</p>
            <p className='subTitle'>{trad.sub_title_website[lang]}</p>
            <p className='text'>{trad.descrip_website[lang]}</p>
        </div>
    )
}

export default Banner;