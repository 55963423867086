import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGoPage } from "../../hooks/useGoPage";
import { login } from "../../redux";

import Input from "../../components/Input";
import InputCode from "../../components/InputCode";

import "./style.css"

function ForgetPassword() {
    const goPage = useGoPage();
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);

    const [code, setCode] = useState('');
    const [msgError, setMsgError] = useState('');

    const [saveLog, setSaveLog] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    async function getAccount() {
        const req = await fetch(`https://api.pro-vide.eu/user/account-forget/${formData.email}/${lang}`);

        if (req.status === 400) {
            setMsgError(trad.error_email_invalid[lang]);
        }

        if (req.status === 404 || req.status === 401) {
            setMsgError(trad.error_user_not_found[lang]);
        }

        if (req.status === 500) {
            setMsgError(trad.error_send_code[lang]);
        }

        if (req.status !== 200) {
            setTimeout(() => {
                setMsgError('');
            }, 6000);
            return
        }

        const res = await req.json();

        if (res) {
            setStep(2);
        }
        else {
            window.location.reload()
        }
    }

    async function checkCode() {
        const req = await fetch(`https://api.pro-vide.eu/user/account-forget/${lang}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: formData.email, code: code })
        });

        if (req.status === 400) {
            setMsgError(trad.error_data_invalid[lang]);
        }

        if (req.status === 401 && req.counter) {
            setMsgError(trad.error_code_invalid[lang] + ', ' + req.couter + ' ' + trad.msg_counter_code[lang]);
        }

        if (req.status === 401 && !req.counter) {
            setMsgError(trad.msg_resend_code[lang] + formData.email);
        }

        if (req.status === 404) {
            setMsgError(trad.error_user_not_found[lang]);
        }

        if (req.status === 500) {
            setMsgError(trad.error_send_code[lang]);
        }

        if (req.status !== 200) {
            setTimeout(() => {
                setMsgError('');
            }, 6000);
            return
        }

        const res = await req.json();

        if (res && res._id) {
            setSaveLog(res)
            setStep(3);
        }
        else {
            window.location.reload()
        }
    }

    async function updatePassword() {
        const req = await fetch(`https://api.pro-vide.eu/user/account-forget/${saveLog._id}`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${saveLog.token}`
            },
            body: JSON.stringify({ password: formData.password })
        });

        if (req.status === 400) {
            setMsgError(trad.error_pw_invalid[lang]);
        }

        if (req.status === 404 || req.status === 401) {
            setMsgError(trad.error_user_not_found[lang]);
        }

        if (req.status === 500) {
            setMsgError(trad.error_code[lang]);
        }

        if (req.status !== 200) {
            setTimeout(() => {
                setMsgError('');
            }, 6000);
            return
        }

        const res = await req.json();

        if (res) {
            sessionStorage.setItem("_id", saveLog._id);
            sessionStorage.setItem("token", saveLog.token);
            sessionStorage.setItem("lastname", saveLog.lastname);
            sessionStorage.setItem("password", formData.password);
            sessionStorage.setItem("firstname", saveLog.firstname);

            dispatch(login({ ...saveLog, password: formData.password }));
            goPage('');
        }
        else {
            window.location.reload()
        }
    }

    return (
        <section className="mdl_forget_pw">
            <div className="content">
                <span className="title_modal">{trad[`mdl_pw_forget_title_step${step}`][lang]}</span>
                <p className="text">{trad[`mdl_pw_forget_text_step${step}`][lang]}</p>

                {step && step === 1 &&
                    <Input
                        className="forget_pw"
                        type="text"
                        placeholder={trad.placeholder_inp_email[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel="email"
                    />
                }

                {step && step === 2 &&
                    <InputCode value={code} setValue={setCode} />
                }

                {step && step === 3 &&
                    <Input
                        className="forget_pw inp_pw"
                        type="password"
                        placeholder={trad.placeholder_inp_newpassword[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel="password"
                    />
                }

                {msgError && msgError !== '' &&
                    <p className="msg_error">
                        <i className="fas fa-triangle-exclamation"></i>
                        {msgError}
                    </p>
                }

                {step && step === 1 && <button className='btn_submit' onClick={() => getAccount()}>{trad.btn_valid[lang]}</button>}
                {step && step === 2 && <button className='btn_submit' onClick={() => checkCode()}>{trad.btn_valid[lang]}</button>}
                {step && step === 3 && <button className='btn_submit' onClick={() => updatePassword()}>{trad.btn_valid[lang]}</button>}
            </div>
        </section>
    )
}

export default ForgetPassword;