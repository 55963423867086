import { useDispatch } from "react-redux";
import { addCategoriesToState, addProductsToState, addAchievementsToState, addServicesToState, start_loading, end_loading } from "../redux";


export const useGetAllData = () => {
    const dispatch = useDispatch();

    const getAllData = async (lang) => {
        dispatch(start_loading());

        const req = await fetch(`https://api.pro-vide.eu/public/all_data/${lang}`);
        
        if (req.status !== 200) {
            return alert(`(Error ${req.status}) ${req.message}`);
        }

        const res = await req.json();        

        const categories = res.categories.sort((a, b) => a.order - b.order);
        const products = res.products.sort((a, b) => a.order - b.order);
        const services = res.services.sort((a, b) => a.order - b.order);
        const achievements = res.achievements.sort((a, b) => a.order - b.order);
        
        dispatch(addCategoriesToState(categories));
        dispatch(addProductsToState(products));
        dispatch(addServicesToState(services));
        dispatch(addAchievementsToState(achievements));

        dispatch(end_loading());
    };

    return getAllData;
};
