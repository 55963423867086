import { useSelector } from "react-redux";
import { useGoPage } from "../../hooks/useGoPage";

import "./style.css"

import logo_pro_vide from './../../assets/logo_pro-vide.png'
import background_icon from './../../assets/svg/backgroung_footer-location.svg'
import location_icon from './../../assets/svg/location_icon-footer.svg'
import email_icon from './../../assets/svg/email_icon-footer.svg'
import phone_icon from './../../assets/svg/phone_icon-footer.svg'

function Footer() {
    const goPage = useGoPage();
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    return (
        <footer>
            <div className="content">
                <div className="footer_menu">
                    <ul>
                        <li><h4>{trad.title_compagny_footer[lang]}</h4></li>
                        <li><button onClick={() => goPage('achievements')}>{trad.btn_page_achievements[lang]}</button></li>
                        <li><button onClick={() => goPage('contact')}>{trad.btn_page_contact[lang]}</button></li>
                        <li><button onClick={() => goPage('about')}>{trad.btn_page_about[lang]}</button></li>
                    </ul>

                    <ul>
                        <li><h4>{trad.title_account_footer[lang]}</h4></li>
                        <li><button onClick={() => goPage('settings')}>{trad.btn_page_settings[lang]}</button></li>
                        <li><button onClick={() => goPage('historical')}>{trad.btn_page_history[lang]}</button></li>
                        <li><button onClick={() => goPage('cart')}>{trad.btn_page_cart[lang]}</button></li>
                    </ul>

                    <ul>
                        <li><h4>{trad.title_legale_notices_footer[lang]}</h4></li>
                        <li><button onClick={() => goPage('general_conditions_of_use')}>{trad.btn_page_cgu[lang]}</button></li>
                        <li><button onClick={() => goPage('privacy_policy')}>{trad.btn_page_pc[lang]}</button></li>
                    </ul>

                    <div className="btn_home" >
                        <img src={logo_pro_vide} alt="Logo" />
                        <h1>Pro-vide<span>{trad.sub_title_website[lang]}</span></h1>
                    </div>
                    
                </div>

                <div className="footer_location">
                    <img className='back_icon' src={background_icon} alt='' />

                    <h4>{trad.title_location_footer[lang]}</h4>

                    <ul>
                        <li>
                            <img src={location_icon} alt='' />
                            <p>24 Bis Route de Fougères<br/>41120 CORMERAY<br/>France</p>
                        </li>

                        <li>
                            <img src={email_icon} alt='' />
                            <p>contact@pro-vide.eu</p>
                        </li>
                        
                        <li>
                            <img src={phone_icon} alt='' />
                            <p>02 55 99 65 05</p>
                        </li>
                    </ul>
                </div>

                <p className="license">{trad.text_license_footer[lang]}</p>
            </div>
        </footer>
    )
}

export default Footer;