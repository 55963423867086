import { useDispatch, useSelector } from "react-redux";
import { start_loading, end_loading } from "../redux";

export const usePreSignup = () => {
    const dispatch = useDispatch();

    const trad = useSelector((state) => state.trad);
    const lang = useSelector((state) => state.menu.lang);

    const preSignup = async (data) => {
        dispatch(start_loading());

        try {
            const req = await fetch(`https://api.pro-vide.eu/user/pre-signup`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    lang: lang
                }),
            });

            if (req.status === 500) {
                throw new Error(trad.error_server[lang]);
            }

            if (req.status === 400) {
                throw new Error(trad.error_email_or_pw[lang]);
            }

            if (req.status === 409) {
                throw new Error(trad.error_user_already_registered[lang]);
            }

            await req.json();
            return '';
        }
        catch (error) {
            return error.message;
        }
        finally {
            dispatch(end_loading());
        }
    };

    return preSignup;
};