import { useEffect, useState } from "react";

import "./style.css"

function InputCode({ value, setValue }) {
    const [focus, setFocus] = useState(false);

    const update = (e) => {
        const code = e.target.value;

        if (code.length > -1 && code.length < 9) {
            setValue(code);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                event.target.closest(".input_code") === null &&
                document.activeElement !== event.target
            ) {
                setFocus(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="input_code">
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>
            <i className={`box ${focus && 'actif'}`}></i>

            <input
                type="text"
                value={value}
                onChange={(e) => update(e)}
                onFocus={() => setFocus(true)}
            />
        </div>
    )
}

export default InputCode;