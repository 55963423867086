import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";

import MainMenu from "../MainMenu";

import "./style.css"

function ProdAssociated({ data, windowWidth }) {
    const fetchImage = useFetchImage();

    const [imgList, setImgList] = useState([]);
    const [prodList, setProdList] = useState([]);

    const products = useSelector(state => state.data.products);

    useEffect(() => {
        const newList = data.map(id => products.find(prod => prod._id === id)).filter(Boolean);
        setProdList(newList);
    }, [data, products])

    useEffect(() => {
        if (prodList.length === 0) {
            setImgList([]);
            return
        }

        const fetchImg = async () => {
            let newImages = [];

            for (let product of prodList) {
                if (product.images[0] && product.images[0] !== '') {
                    const result = await fetchImage(product.images[0]);
                    newImages.push(result);
                }
            }

            setImgList(newImages);
        }
        fetchImg();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prodList])

    return prodList.length !== 0 && imgList.length !== 0 && (
        <MainMenu className='prod-associated' data={prodList} images={imgList} childRoute='product' windowWidth={windowWidth} />
    )
}

export default ProdAssociated;