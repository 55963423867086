import { useDispatch } from "react-redux";
import { close, logout } from "../redux";

import { useGoPage } from "./useGoPage";


export const useLogout = () => {
    const dispatch = useDispatch();
    const goPage = useGoPage();

    const kill_session = (route) => {
        sessionStorage.removeItem('_id');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('password');
        sessionStorage.removeItem('lastname');
        sessionStorage.removeItem('firstname');

        localStorage.removeItem('_id');
        localStorage.removeItem('token');
        localStorage.removeItem('password');
        localStorage.removeItem('lastname');
        localStorage.removeItem('firstname');

        dispatch(logout());
        dispatch(close());
        
        goPage(`${route}`);
    };

    return kill_session;
};
