import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { end_loading, start_loading } from "../../redux";

import { useGoPage } from "../../hooks/useGoPage";
import { useLogout } from "../../hooks/useLogout";
import { useFormatPrice } from "../../hooks/useFormatPrice";

import "./style.css"

import cart_icon from './../../assets/svg/cart-icon_page_cart.svg'
import phone_icon from './../../assets/svg/phone-icon_page_cart.svg'
import trash_icon from './../../assets/svg/btn-delProdToCart.svg'
import RequestQuote from "../../modals/RequestQuote";

function Cart() {
    const goPage = useGoPage();
    const dispatch = useDispatch();
    const kill_session = useLogout();
    const formatPrice = useFormatPrice();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const _id = useSelector(state => state.user._id);
    const token = useSelector(state => state.user.token);

    const [mdlRequest, setMdlRequest] = useState(false);
    const [cartIsUpdate, setCartIsUpdate] = useState(false);
    const [cartSave, setCartSave] = useState([]);
    const [cart, setCart] = useState([]);

    useEffect(() => {
        if (!_id || !token) {
            return
        }

        dispatch(start_loading());

        const fetchCart = async () => {
            const req = await fetch(`https://api.pro-vide.eu/user/cart/${_id}/${lang}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const res = await req.json();

            if (req.status === 401) {
                kill_session();
                goPage('login');
                return
            }

            if (req.status !== 200 || !res) {
                goPage('error');
                return
            }

            setCart(JSON.parse(JSON.stringify(res.cart)));
            setCartSave(res.cart);
        }
        fetchCart()

        dispatch(end_loading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, lang, token]);

    const calcTotalHT = () => {
        let total = 0;

        for (let prod of cart) {
            total += parseInt(prod.qte) * parseFloat(prod.price);
        }

        return total
    }

    const calcTVA = (totalHT) => {
        const TVA = parseFloat(totalHT) / 5;
        return TVA
    }

    const isValidQuantity = (qte) => {
        let num = parseInt(qte, 10);

        if (isFinite(num) && num >= 0) {
            return true;
        }
        return false;
    }

    const setQuantity = async (e, idx) => {
        const value = e.target.value;

        if (!isValidQuantity(value)) {
            return
        }

        const newCart = [...cart];
        newCart[idx].qte = parseInt(value);

        setCart(newCart);
        setCartIsUpdate(true);
    }

    const handleDelete = async (ref) => {
        dispatch(start_loading());
        let tempCart = [...cart];
        
        try {
            const req = await fetch(`https://api.pro-vide.eu/user/edit-cart/${_id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...ref, qte: 0 })
            });
            const res = await req.json();

            if (req.status !== 200 || res !== true) {
                dispatch(end_loading());
                goPage('error');
                return;
            }

            tempCart = tempCart.filter(e => e.refId !== ref.refId);
        }
        catch (error) {
            console.error('Erreur lors de la mise à jour de la quantité:', error);
            goPage('error');
            return;
        }

        setCart(JSON.parse(JSON.stringify(tempCart)));
        setCartSave(tempCart);

        setCartIsUpdate(false);
        dispatch(end_loading());
    };

    const handleCancel = () => {
        setCart(JSON.parse(JSON.stringify(cartSave)));
        setCartIsUpdate(false);
    };

    const handleEdit = async () => {
        dispatch(start_loading());
        let tempCart = [...cart];

        for (let idx = 0; idx < cart.length; idx++) {
            const ref = cart[idx];

            if (parseInt(ref.qte) !== parseInt(cartSave[idx].qte)) {
                try {
                    const req = await fetch(`https://api.pro-vide.eu/user/edit-cart/${_id}`, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ ...ref })
                    });
                    const res = await req.json();

                    if (req.status !== 200 || res !== true) {
                        console.log(res);
                        goPage('error');
                        return;
                    }

                    tempCart[idx].qte = parseInt(ref.qte);
                }
                catch (error) {
                    console.error('Erreur lors de la mise à jour de la quantité:', error);
                    goPage('error');
                    return;
                }
            }
        }

        setCart(JSON.parse(JSON.stringify(tempCart)));
        setCartSave(tempCart);

        setCartIsUpdate(false);
        dispatch(end_loading());
    };


    return cart && cartSave && (
        <div className='page_cart'>
            <h2>{trad.title_cart[lang]}</h2>

            <div className="container_board_table">
                <div className="board">
                    <div className="content">
                        <img src={cart_icon} alt="Icon" />

                        <ul className="total">
                            <li>
                                <h4>{trad.total_ht[lang]}</h4>
                                <span>{formatPrice(calcTotalHT())}€</span>
                            </li>

                            <li>
                                <h4>{trad.tva[lang]}</h4>
                                <span>{formatPrice(calcTVA(calcTotalHT()))}€</span>
                            </li>

                            <li className="total_ttc">
                                <h4>{trad.total_ttc[lang]}</h4>
                                <span>{formatPrice(calcTVA(calcTotalHT()) + calcTotalHT())}€</span>
                            </li>
                        </ul>

                        <ul className="list">
                            {cart.length === 0 && <li className="void">{trad.cart_is_void[lang]}</li>}

                            {cart.length !== 0 && cart.map((ref, idx) =>
                                <li key={idx}>
                                    <p>{ref.reference}</p>
                                    <p>{formatPrice(ref.price)}€ <span>x{ref.qte}</span></p>
                                </li>
                            )}
                        </ul>

                        <button
                            onClick={() => setMdlRequest(true)}
                            className={cart.length !== 0 ? 'btn_ask_quote btn_actif' : 'btn_ask_quote btn_inactif'}
                        >{trad.btn_ask_quote[lang]}</button>
                    </div>

                    <div className="contact">
                        <img src={phone_icon} alt="Icon" />
                        <p>{trad.text_contact_cart[lang]}</p>
                    </div>
                </div>

                {cart.length !== 0 &&
                    <ul className="table">
                        {cart.map((ref, idx) =>
                            <li
                                key={idx}
                                className={parseInt(ref.qte) !== parseInt(cartSave[idx].qte) ? 'inUpdate' : ''}
                            >
                                <div className="container_img">
                                    <img src={ref.images.small_image} alt="view" />
                                </div>

                                <div className="descrip">
                                    <div className="identification">
                                        <h3>{ref.name} <p>{ref.reference}</p></h3>
                                        <button onClick={() => handleDelete(ref)}><img src={trash_icon} alt="X" /></button>
                                    </div>

                                    <div className="price_qte">
                                        <p>
                                            <i>
                                                {trad.unity_price[lang]}
                                                <span>{trad.up_ht[lang]}</span>:
                                            </i>
                                            <span className='price'>{formatPrice(ref.price)}€</span>
                                        </p>

                                        <p>
                                            {trad.unity_total[lang]}
                                            <span className='price'>{formatPrice(parseFloat(ref.price) * parseInt(ref.qte))}€</span>
                                        </p>

                                        <p>
                                            {trad.unity_quantity[lang]}
                                            <input type="number" onChange={(e) => setQuantity(e, idx)} value={ref.qte} min={1} />
                                        </p>
                                    </div>
                                </div>
                            </li>
                        )}

                        {cartIsUpdate &&
                            <li className='panel_edit'>
                                <button
                                    className='btn_cancel'
                                    onClick={handleCancel}
                                >{trad.cancel[lang]}</button>

                                <button
                                    className='btn_edit'
                                    onClick={handleEdit}
                                >{trad.edit[lang]}</button>
                            </li>
                        }
                    </ul>
                }
            </div>

            {mdlRequest && <RequestQuote showMdl={setMdlRequest} />}
        </div>
    )
}

export default Cart;