import { useState } from "react";
import { useSelector } from "react-redux";

import Input from "../../components/Input";
import BtnCheck from "../../buttons/BtnCheck";

import { usePreSignup } from "../../hooks/usePreSignup";
import { useCheckFormData } from "../../hooks/useCheckFormData";

import "./style.css"

function FormSignup({ formData, setFormData, setShowConfirmSignup }) {
    const checkFormData = useCheckFormData();
    const preSignup = usePreSignup();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [alertAnime, setAlertAnime] = useState({
        society: false,
        service: false,
        lastname: false,
        firstname: false,
        email: false,
        pw: false,
        cellphoneNumber: false,
        address_street: false,
        address_city: false,
        address_country: false,
        address_zip_code: false
    });

    const [alertCond, setAlertCond] = useState(false);
    const [msgError, setMsgError] = useState('');

    const formSubmit = async (e) => {
        e.preventDefault();

        if (!formData.cond) {
            setAlertCond(true);

            setTimeout(() => {
                setAlertCond(false);
            }, 2500)
            return
        }

        const fields = [
            'society',
            'lastname',
            'firstname',
            'email',
            'pw',
            'cellphoneNumber',
            'address_street',
            'address_city',
            'address_country',
            'address_zip_code',
        ];

        const dataIsCheck = checkFormData(formData, setAlertAnime, fields);

        if (!dataIsCheck) {            
            setTimeout(() => {
                setAlertAnime({
                    society: false,
                    service: false,
                    lastname: false,
                    firstname: false,
                    email: false,
                    pw: false,
                    cellphoneNumber: false,
                    address_street: false,
                    address_city: false,
                    address_country: false,
                    address_zip_code: false
                })
            }, 2000)
            return
        }

        const response = await preSignup(formData);

        if (response !== '') {
            setMsgError(response);

            setTimeout(() => {
                setMsgError('');
            }, 5000)
            return
        }

        setShowConfirmSignup(true);
    }

    return formData && alertAnime && (
        <form
            className='form_signup'
            onSubmit={(e) => formSubmit(e)}
        >
            <span className="title_form">{trad.title_form_signup[lang]}</span>

            <div className="container_input">
                <Input
                    className='signup'
                    subClass={alertAnime.society}
                    label={trad.label_company[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_company[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='society'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.service}
                    label={trad.label_service[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_service[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='service'
                />

                <Input
                    className='signup'
                    label={trad.label_siret[lang] + ' : *'}
                    type='text'
                    placeholder={trad.placeholder_inp_siret[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='siret'
                />

                <i className="item_invisible">{/*Vide*/}</i>

                <Input
                    className='signup'
                    subClass={alertAnime.lastname}
                    label={trad.label_lastname[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_lastname[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='lastname'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.firstname}
                    label={trad.label_firstname[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_firstname[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='firstname'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.email}
                    label={trad.label_email[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_email[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='email'
                />

                <Input
                    className='signup inp_pw'
                    subClass={alertAnime.pw}
                    label={trad.label_password[lang] + ' :'}
                    type='password'
                    placeholder={trad.placeholder_inp_password[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='pw'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.cellphoneNumber}
                    label={trad.label_cellphone[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_cellphone[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='cellphoneNumber'
                />

                <Input
                    className='signup'
                    label={trad.label_phone[lang] + ' : *'}
                    type='text'
                    placeholder={trad.placeholder_inp_phone[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='phoneNumber'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.address_street}
                    label={trad.label_address[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_address[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_street'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.address_city}
                    label={trad.label_city[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_city[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_city'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.address_zip_code}
                    label={trad.label_zipcode[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_zipcode[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_zip_code'
                />

                <Input
                    className='signup'
                    subClass={alertAnime.address_country}
                    label={trad.label_country[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_country[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_country'
                />
            </div>

            <div className="container_opt_msg">
                <BtnCheck
                    className={alertCond ? 'alert' : ''}
                    label={trad.check_cond_user[lang]}
                    setState={setFormData}
                    stateLabel='cond'
                    state={formData}
                />

                <p className='msg_optional'>{trad.msg_optional[lang]}</p>

                {msgError && msgError !== '' &&
                    <p className="msg_error">
                        <i className="fas fa-triangle-exclamation"></i>
                        {msgError}
                    </p>
                }

                <button className='btn_submit' type='submit'>{trad.form_signup_btn[lang]}</button>
            </div>
        </form>
    )
}

export default FormSignup;