import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, open } from "../../redux";

import { useGoPage } from "./../../hooks/useGoPage";
import { useGetAllData } from "../../hooks/useGetAllData";

import BtnHome from "../../buttons/BtnHome";
import BtnLang from "../../buttons/BtnLang";
import BtnWithIcon from "../../buttons/BtnWithIcon";

import "./style.css"

import chevron_icon from './../../assets/svg/chevron_icon-nav-desk.svg'
import btn_menu_icon from './../../assets/svg/nav_btn-mobile.svg'

function Header() {
    const goPage = useGoPage();
    const getAllData = useGetAllData();

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const user_menu = useSelector(state => state.menu.user_menu);

    const userIsLogin = user && user.token && typeof user.token === 'string' && user.token !== '';

    // Verification de session
    useEffect(() => {
        const id_local = localStorage.getItem('_id');
        const token_local = localStorage.getItem('token');
        const password_local = localStorage.getItem('password');
        const lastname_local = localStorage.getItem('lastname');
        const firstname_local = localStorage.getItem('firstname');

        if (id_local && id_local !== '') {
            sessionStorage.setItem('_id', id_local);
            sessionStorage.setItem('token', token_local);
            sessionStorage.setItem('password', password_local);
            sessionStorage.setItem('lastname', lastname_local);
            sessionStorage.setItem('firstname', firstname_local);

            dispatch(login({
                _id: id_local,
                token: token_local,
                password: password_local,
                firstname: lastname_local,
                lastname: firstname_local
            }))
            return
        }

        const id_session = sessionStorage.getItem('_id');
        const token_session = sessionStorage.getItem('token');
        const password_session = sessionStorage.getItem('password');
        const lastname_session = sessionStorage.getItem('lastname');
        const firstname_session = sessionStorage.getItem('firstname');

        if (id_session && id_session !== '') {
            dispatch(login({
                _id: id_session,
                token: token_session,
                password: password_session,
                firstname: lastname_session,
                lastname: firstname_session
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Mise à jour des données en fonction de la langue
    useEffect(() => {
        getAllData(lang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    return (
        <header>
            <div className="content">
                <BtnHome />

                <div className="controle_panel">
                    <BtnLang />

                    {!userIsLogin ? (
                        <BtnWithIcon
                            label={trad.btn_page_login[lang]}
                            icon={null}
                            onClick={() => goPage('login')}
                            className='btn_page_login f_desktop'
                        />
                    ) : (
                        <BtnWithIcon
                            label={user.firstname + '.' + user.lastname[0]}
                            icon={chevron_icon}
                            onClick={() => dispatch(open('user_menu'))}
                            className={`btn_user_menu f_desktop ${user_menu && 'actif'}`}
                        />
                    )}

                    {/* {!userIsLogin && (
                        <BtnWithIcon
                            label=''
                            icon={btn_user_icon}
                            onClick={() => goPage('login')}
                            className='btn_login_mobile f_mobile'
                        />
                    )} */}

                    <BtnWithIcon
                        label=''
                        icon={btn_menu_icon}
                        onClick={() => dispatch(open('menu_mobile'))}
                        className='btn_menu_mobile f_mobile'
                    />
                </div>
            </div>
        </header>
    )
}

export default Header;