import { useSelector } from "react-redux";
import { useState } from "react";

import { useCheckFormData } from "../../hooks/useCheckFormData";

import Input from "../../components/Input";

import "./style.css"

import icon from './../../assets/svg/lock_settings-icon.svg';

function FormPassword({ setMdlConf }) {
    const checkFormData = useCheckFormData();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [formData, setFormData] = useState({ new_pw: '', conf_pw: '' });
    const [alert, setAlert] = useState({ new_pw: false, conf_pw: false });

    const [editPw, setEditPw] = useState(false);
    const [msgConf, setMsgConf] = useState('');

    const updatePassword = (e) => {
        e.preventDefault();

        const fields = ['new_pw', 'conf_pw'];

        const check = checkFormData(formData, setAlert, fields);

        if (!check) {
            setTimeout(() => {
                setAlert({ new_pw: false, conf_pw: false })
            }, 2000)
            return
        }

        if (formData.new_pw !== formData.conf_pw) {
            setMsgConf(trad.pw_not_identical[lang]);
            setTimeout(() => {
                setMsgConf('');
            }, 2500);
        }

        if (formData.new_pw.length < 8) {
            setMsgConf(trad.pw_lenght[lang]);
            setTimeout(() => {
                setMsgConf('');
            }, 2500);
        }

        setMdlConf({ data: formData, type: 'password' });
    }

    return (
        <form className='form_settings form_password'>
            <h3>
                <img src={icon} alt="Icon" />
                {trad.title_form_password[lang]}
            </h3>


            {!editPw &&
                <div className="container_inp">
                    <Input
                        className='settings lock'
                        label={trad.label_password[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_password[lang]}
                        value='*******************'
                        setValue={() => { }}
                        stateLabel=''
                        lock={true}
                    />
                    <button
                        className='btn_edit'
                        onClick={() => setEditPw(true)}
                    >
                        {trad.btn_edit_password[lang]}
                    </button>
                </div>
            }

            {editPw &&
                <div className="container_inp">
                    <Input
                        className='settings'
                        subClass={alert.new_pw}
                        label={trad.label_new_pw[lang] + ' :'}
                        type='password'
                        placeholder={trad.placeholder_inp_new_pw[lang]}
                        value={formData.new_pw}
                        setValue={setFormData}
                        stateLabel='new_pw'
                    />

                    <Input
                        className='settings'
                        subClass={alert.conf_pw}
                        label={trad.label_conf_new_pw[lang] + ' :'}
                        type='password'
                        placeholder={trad.placeholder_inp_conf_new_pw[lang]}
                        value={formData.conf_pw}
                        setValue={setFormData}
                        stateLabel='conf_pw'
                    />
                </div>
            }

            {editPw &&
                <div className="panel">
                    <button type='button' onClick={() => setEditPw(false)} className='btn_red'>{trad.cancel[lang]}</button>
                    <button type='submit' onClick={(e) => updatePassword(e)} className='btn_blue'>{trad.edit[lang]}</button>
                </div>
            }

            {msgConf && msgConf !== '' &&
                <div className="msg_conf">
                    <div className="content">
                        <p>{msgConf}</p>
                    </div>
                </div>
            }
        </form>
    )
}

export default FormPassword;