import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGoPage } from "./../../hooks/useGoPage";

import "./style.css"

import icon_download from './../../assets/svg/download-icon.svg';
import icon_repeat from './../../assets/svg/icon_repeat.svg';
import RequestPOR from "../../modals/RequestPOR";

function Historical() {
    const goPage = useGoPage();

    const user = useSelector(state => state.user);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [filters, setFilters] = useState({ years: '', type: 'all' });
    const [years, setYears] = useState([]);

    const [repeatRequest, setRepeatRequest] = useState(false);
    const [history, setHistory] = useState(null);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        setFilters({ ...filters, years: currentYear.toString() });

        let array = [];

        for (let year = currentYear; year >= 2020; year--) {
            array.push(year);
        }
        setYears(array);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Récupération de la largeur de l'écran
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchHistory = async () => {
            if (!filters.years || !user._id || !user.token) {
                setHistory(null);
                return
            }

            const req = await fetch(`https://api.pro-vide.eu/user/historical/${user._id}/${lang}/${filters.years}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const res = await req.json();

            if (req.status !== 200 || !res.history) {
                goPage('error');
                return
            }

            setHistory(res.history);
        }
        fetchHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, filters.years, lang])

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('fr-FR', options);
    }

    return (
        <div className='page_historical'>
            <h2>{trad.title_historical[lang]}</h2>

            <div className="filters">
                <div className="filter">
                    <label>{trad.filters[lang]} :</label>

                    <select
                        value={filters.years}
                        onChange={(e) => setFilters({ ...filters, years: e.target.value })}
                    >
                        {years.map(year =>
                            <option key={year} value={year}>{year}</option>
                        )}
                    </select>
                </div>

                <div className="filter">
                    <label>Type :</label>

                    <select value={filters.type} onChange={(e) => setFilters({ ...filters, type: e.target.value })}>
                        <option value="all">{trad.all[lang]}</option>
                        <option value="prod">{trad.btn_menu_prod[lang]}</option>
                        <option value="ref">{trad.opt_references[lang]}</option>
                        <option value="serv">{trad.btn_menu_serv[lang]}</option>
                    </select>
                </div>
            </div>

            <section className="history">
                {history && history.length !== 0 &&
                    <ul>
                        {windowWidth > 767 && <li className='head'>
                            <p>{trad.date[lang]}</p>
                            <p>{trad.type[lang]}</p>
                            <p>{trad.product_or_service[lang]}</p>
                            <p className='invisible'></p>
                        </li>}

                        {windowWidth > 767 && history.map((element, idx) => (element.type === filters.type || filters.type === 'all') &&
                            <li key={idx}>
                                <span className='date'>{formatDate(element.date)}</span>
                                <p>
                                    {element.type === 'prod' && trad.btn_menu_prod[lang]}
                                    {element.type === 'ref' && trad.opt_references[lang]}
                                    {element.type === 'serv' && trad.btn_menu_serv[lang]}
                                </p>
                                <p>
                                    {element.cart[0].reference}
                                    {element.cart.length >= 2 && ', ' + element.cart[1].reference}
                                    <span className='other'>{element.cart.length >= 3 && ` and ${element.cart.length - 2} other elements`}</span>
                                </p>

                                {element.type === 'ref' &&
                                    <button className='btn_download_quote'>
                                        <a href={element.devis} target="blank">
                                            <img src={icon_download} alt="Icon" />
                                            {trad.btn_download_quote[lang]}
                                        </a>
                                    </button>
                                }

                                {element.type !== 'ref' &&
                                    <button className='btn_repeat_request'>
                                        <span onClick={() => setRepeatRequest(element)}>
                                            <img src={icon_repeat} alt="Icon" />
                                            {trad.btn_repeat_request[lang]}
                                        </span>
                                    </button>
                                }
                            </li>
                        )}

                        {windowWidth < 768 && history.map((element, idx) => (element.type === filters.type || filters.type === 'all') &&
                            <li key={idx}>
                                <div>
                                    <p className='label'>{trad.date[lang]} :</p>
                                    <span className='date'>{formatDate(element.date)}</span>
                                </div>

                                <div>
                                    <p className='label'>{trad.type[lang]} :</p>
                                    <p>
                                        {element.type === 'prod' && trad.btn_menu_prod[lang]}
                                        {element.type === 'ref' && trad.opt_references[lang]}
                                        {element.type === 'serv' && trad.btn_menu_serv[lang]}
                                    </p>
                                </div>

                                <div>
                                    <p className='label'>{trad.product_or_service[lang]} :</p>
                                    <p>
                                        {element.cart[0].reference}
                                        {element.cart.length >= 2 && ', ' + element.cart[1].reference}
                                        <span className='other'>{element.cart.length >= 3 && ` and ${element.cart.length - 2} other elements`}</span>
                                    </p>
                                </div>

                                {element.type === 'ref' &&
                                    <button className='btn_download_quote'>
                                        <a href={element.devis} target="blank">
                                            <img src={icon_download} alt="Icon" />
                                            {trad.btn_download_quote[lang]}
                                        </a>
                                    </button>
                                }

                                {element.type !== 'ref' &&
                                    <button className='btn_repeat_request'>
                                        <span onClick={() => setRepeatRequest(element)}>
                                            <img src={icon_repeat} alt="Icon" />
                                            {trad.btn_repeat_request[lang]}
                                        </span>
                                    </button>
                                }
                            </li>
                        )}
                    </ul>
                }
            </section>

            {repeatRequest && !repeatRequest.cart[0].refId && <RequestPOR elementId={repeatRequest.cart[0]._id} type={repeatRequest.type} qte={repeatRequest.cart[0].qte} text={repeatRequest.request} showMdl={setRepeatRequest} />}
            {repeatRequest && repeatRequest.cart[0].refId && <RequestPOR elementId={repeatRequest.cart[0]._id} refId={repeatRequest.cart[0].refId} type={repeatRequest.type} qte={repeatRequest.cart[0].qte} text={repeatRequest.request} showMdl={setRepeatRequest} />}
        </div>
    )
}

export default Historical;