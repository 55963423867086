import { useDispatch } from "react-redux";

import "./style.css"
import { close } from "../../redux";

function Lock() {    
    const dispatch = useDispatch();

    return (
        <div onClick={() => dispatch(close())} className='lock_page'>
            {/* Verouille la page */}
        </div>
    )
}

export default Lock;