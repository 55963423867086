import { useSelector } from "react-redux";
import { useState } from "react";

import FormLogin from "../../forms/FormLogin";
import FormSignup from "../../forms/FormSignup";
import ConfirmSignup from "../../modals/ConfirmSignup";
import ForgetPassword from "../../modals/ForgetPassword";

import "./style.css"

function Login() {
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [showConfirmSignup, setShowConfirmSignup] = useState(false);
    const [showForgetPassword, setShowForgetPassword] = useState(false);

    const [formData, setFormData] = useState({
        society: '',
        service: '',
        siret: '',

        lastname: '',
        firstname: '',

        email: '',
        pw: '',

        cellphoneNumber: '',
        phoneNumber: '',

        address_street: '',
        address_city: '',

        address_country: '',
        address_zip_code: '',

        cond: false,
    });

    return (
        <div className='page page_login'>
            <span className="title">{trad.title_page_login[lang]}</span>
            <p className="title_comment">{trad.title_comment_login[lang]}</p>

            <div className="form_container">
                <FormLogin setShowForgetPassword={setShowForgetPassword} />
                <FormSignup formData={formData} setFormData={setFormData} setShowConfirmSignup={setShowConfirmSignup} />

                {showConfirmSignup && <ConfirmSignup formData={formData} />}
                {showForgetPassword && <ForgetPassword />}
            </div>
        </div>
    )
}

export default Login;