import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useCheckFormData } from "../../hooks/useCheckFormData";

import Input from './../../components/Input';

import "./style.css"

import icon from './../../assets/svg/file_settings-icon.svg';

function FormCoordinates({ data, setMdlConf }) {
    const checkFormData = useCheckFormData();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [lastData, setLastData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [updtForm, setUpdtForm] = useState(false);

    const [alertFields, setAlertFields] = useState({
        society: false,
        service: false,
        cellphoneNumber: false,
        address_street: false,
        address_city: false,
        address_country: false,
        address_zip_code: false
    });

    useEffect(() => {
        if (!data) {
            setLastData(null);
            setFormData(null);
            return
        }
        setLastData(data);
        setFormData(data);
    }, [data]);

    // Vérification de modification des données (afficher le btn 'Modifier')
    useEffect(() => {
        if (!formData || !lastData) {
            setUpdtForm(false);
            return
        }

        const isModified = Object.keys(formData).some(key => formData[key] !== lastData[key]);
        setUpdtForm(isModified);
    }, [formData, lastData]);

    const checkForm = (e) => {
        e.preventDefault();

        const fields = [
            'society',
            'cellphoneNumber',
            'address_street',
            'address_city',
            'address_country',
            'address_zip_code',
        ];

        const check = checkFormData(formData, setAlertFields, fields);

        if (!check) {            
            setTimeout(() => {
                setAlertFields({
                    society: false,
                    service: false,
                    cellphoneNumber: false,
                    address_street: false,
                    address_city: false,
                    address_country: false,
                    address_zip_code: false
                })
            }, 2000)
            return
        }

        setMdlConf({ data: formData, type: 'all' });
    }


    return data && formData && lastData && (
        <form className='form_settings form_coordinates'>
            <h3>
                <img src={icon} alt="Icon" />
                {trad.title_form_coordinates[lang]}
            </h3>

            <div className="container_inp">
                <Input
                    className='settings lock'
                    label={trad.label_lastname[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_lastname[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='lastname'
                    lock={true}
                />

                <Input
                    className='settings lock'
                    label={trad.label_firstname[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_firstname[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='firstname'
                    lock={true}
                />

                <Input
                    className='settings'
                    subClass={alertFields.society}
                    label={trad.label_company[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_company[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='society'
                />

                <Input
                    className='settings'
                    subClass={alertFields.service}
                    label={trad.label_service[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_service[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='service'
                />

                <Input
                    className='settings'
                    label={trad.label_siret[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_siret[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='siret'
                />
                
                <i className="invisible">{/*Vide*/}</i>

                <Input
                    className='settings'
                    subClass={alertFields.cellphoneNumber}
                    label={trad.label_cellphone[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_cellphone[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='cellphoneNumber'
                />

                <Input
                    className='settings'
                    label={trad.label_phone[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_phone[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='phoneNumber'
                />

                <Input
                    className='settings'
                    subClass={alertFields.address_street}
                    label={trad.label_address[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_address[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_street'
                />

                <Input
                    className='settings'
                    subClass={alertFields.address_city}
                    label={trad.label_city[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_city[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_city'
                />

                <Input
                    className='settings'
                    subClass={alertFields.address_country}
                    label={trad.label_country[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_country[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_country'
                />

                <Input
                    className='settings'
                    subClass={alertFields.address_zip_code}
                    label={trad.label_zipcode[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_zipcode[lang]}
                    value={formData}
                    setValue={setFormData}
                    stateLabel='address_zip_code'
                />
            </div>

            {updtForm &&
                <div className="panel">
                    <button type='button' onClick={() => setFormData(lastData)} className='btn_red'>{trad.cancel[lang]}</button>
                    <button type='submit' onClick={(e) => checkForm(e)} className='btn_blue'>{trad.edit[lang]}</button>
                </div>
            }
        </form>
    )
}

export default FormCoordinates;