import { useGoPage } from "../../hooks/useGoPage";
import "./style.css"

function SubMenu({ data }) {
    const goPage = useGoPage();

    return (
        <div className="subMenu_mobile">
            {data && data.length !== 0 && data.map((cat, idx) => (
                <button key={idx} onClick={() => goPage(`category/${cat._id}`)}>
                    {cat.name}
                </button>
            ))}
        </div>
    )
}

export default SubMenu;