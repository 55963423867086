import { useSelector } from "react-redux";

import "./style.css"

import logo_pro_vide from './../../assets/logo_pro-vide.png'

function Loader() {
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    return (
        <div className='loader'>
            <div className="btn_home" >
                <img src={logo_pro_vide} alt="Logo" />
                <h1>Pro-vide<span>{trad.sub_title_website[lang]}</span></h1>
            </div>

            <i className="shadow"></i>
        </div>
    )
}

export default Loader;