import { useState } from "react";

import "./style.css"

function SliderImg({ type, data, windowWidth }) {
    const [counter, setCounter] = useState(0);

    return type && data && data.length !== 0 && (
        <div className='slider_img'>
            <div className="view actif">
                {type === 'category' && <img src={data[counter][0] && data[counter][0][`${windowWidth < 700 ? 'small_' : ''}image`]} alt='Illustration' />}
                {type !== 'category' && <img src={data[counter] && data[counter][`${windowWidth < 700 ? 'small_' : ''}image`]} alt='Illustration' />}
            </div>

            {type !== 'category' &&
                <div className="container">
                    {data.map((img, idx) => (
                        <button 
                            key={idx} 
                            onClick={() => setCounter(idx)}
                            className={`btn_preview ${idx === counter ? 'actif' : ''}`}
                        >
                            <img src={img && img[`${windowWidth < 700 ? 'small_' : ''}image`]} alt='Illustration' />
                        </button>
                    ))}
                </div>
            }
        </div>
    )
}

export default SliderImg;