import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { close, set_nav, set_menu } from "../../redux";

import { useGoPage } from "./../../hooks/useGoPage";
import { useLogout } from "../../hooks/useLogout";

import BtnWithIcon from "../../buttons/BtnWithIcon";
import SubMenu from "../SubMenu";

import "./style.css"

import chevron_icon from './../../assets/svg/chevron_icon-nav-desk.svg'
import engine_icon from './../../assets/svg/realisation_icon-nav-desk.svg'
import phone_icon from './../../assets/svg/contact_icon-nav-desk.svg'
import about_icon from './../../assets/svg/about_icon-nav-desk.svg'
import settings_icon from './../../assets/svg/settings_icon-nav.svg'
import cart_icon from './../../assets/svg/cart_icon-nav.svg'
import history_icon from './../../assets/svg/history_icon-nav.svg'
import logout_icon from './../../assets/svg/logout_icon-nav.svg'
import btn_user_icon from './../../assets/svg/icon_btn_nav_login_mob.svg'


function Nav() {
    const goPage = useGoPage();
    const kill_session = useLogout();

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const data = useSelector(state => state.data);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const nav_type = useSelector(state => state.menu.nav_type);
    const nav_menu = useSelector(state => state.menu.nav_menu);
    const menu_mobile = useSelector(state => state.menu.menu_mobile);

    const [subMenuProd, setSubMenuProd] = useState([]);
    const [subMenuServ, setSubMenuServ] = useState([]);

    const userIsLogin = user && user.token && typeof user.token === 'string' && user.token !== '';

    // Veille de la largeur de l'ecran
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                dispatch(set_nav(2));
                dispatch(close());
                return
            }
            dispatch(set_nav(1));
            dispatch(close());
        };

        handleResize();

        return () => window.addEventListener('resize', handleResize);
    }, [dispatch])

    // Récuperation des données pour les menus 'produits' & 'services' du format mobile
    useEffect(() => {
        if (!data || data.categories.length === 0) {
            return
        }

        setSubMenuProd(data.categories.filter(e => !e.parentId && e.type === 'prod'));
        setSubMenuServ(data.categories.filter(e => !e.parentId && e.type === 'serv'));
    }, [data])


    return (nav_type === 1 || (nav_type === 2 && menu_mobile)) && (
        <nav>
            {!userIsLogin && nav_type === 2 && (
                <BtnWithIcon
                    label={trad.btn_page_login[lang]}
                    icon={btn_user_icon}
                    onClick={() => goPage('login')}
                    className='btn_login_mobile f_mobile'
                />
            )}

            <BtnWithIcon
                label={trad.btn_menu_prod[lang]}
                icon={chevron_icon}
                onClick={() => dispatch(set_menu(1))}
                className={`btn_nav btn_open_menu_mobile ${nav_menu === 1 ? 'actif' : ''}`}
            />

            {nav_menu === 1 && nav_type === 2 && <SubMenu data={subMenuProd} />}

            <BtnWithIcon
                label={trad.btn_menu_serv[lang]}
                icon={chevron_icon}
                onClick={() => dispatch(set_menu(2))}
                className={`btn_nav btn_open_menu_mobile ${nav_menu === 2 ? 'actif' : ''}`}
            />

            {nav_menu === 2 && nav_type === 2 && <SubMenu data={subMenuServ} />}

            <BtnWithIcon
                label={trad.btn_page_achievements[lang]}
                icon={engine_icon}
                onClick={() => goPage('achievements')}
                className='btn_nav'
            />

            {userIsLogin && nav_type === 2 && (
                <>
                    <BtnWithIcon
                        label={trad.btn_page_settings[lang]}
                        icon={settings_icon}
                        onClick={() => goPage('settings')}
                        className='btn_nav btn_user_mobile'
                    />

                    <BtnWithIcon
                        label={trad.btn_page_cart[lang]}
                        icon={cart_icon}
                        onClick={() => goPage('cart')}
                        className='btn_nav btn_user_mobile'
                    />

                    <BtnWithIcon
                        label={trad.btn_page_history[lang]}
                        icon={history_icon}
                        onClick={() => goPage('historical')}
                        className='btn_nav btn_user_mobile'
                    />
                </>
            )}

            <BtnWithIcon
                label={trad.btn_page_contact[lang]}
                icon={phone_icon}
                onClick={() => goPage('contact')}
                className='btn_nav'
            />

            <BtnWithIcon
                label={trad.btn_page_about[lang]}
                icon={about_icon}
                onClick={() => goPage('about')}
                className='btn_nav'
            />

            {userIsLogin && nav_type === 2 &&
                <BtnWithIcon
                    label={trad.btn_logout[lang]}
                    icon={logout_icon}
                    onClick={() => kill_session('')}
                    className='btn_nav btn_user_mobile'
                />
            }
        </nav>
    )
}

export default Nav;