export const useFormatPrice = () => {
    const formatPrice = (num) => {
        num = parseFloat(num);

        if (Math.floor(num) === num) {
            return num.toString() + ".00";
        } 
        else {
            let fixedNum = num.toFixed(2);
            return fixedNum.split(".")[1] === "00" ? fixedNum.split(".")[0] : fixedNum;
        }
    };

    return formatPrice;
};
