import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useCheckFormData } from "../../hooks/useCheckFormData";

import Input from "../../components/Input";

import "./style.css"

import icon from './../../assets/svg/compagny_settings-icon.svg';

function FormAddress({ data, setUserProfile }) {
    const navigate = useNavigate();
    const checkFormData = useCheckFormData();

    const user = useSelector(state => state.user);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [selectValue, setAddressCursor] = useState('null');
    const [msgConf, setMsgConf] = useState('');

    const [alertFields, setAlertFields] = useState({
        name: false,
        address_street: false,
        address_city: false,
        address_country: false,
        address_zip_code: false
    });
    const [formData, setFormData] = useState({
        name: '',
        address_street: '',
        address_city: '',
        address_country: '',
        address_zip_code: ''
    });

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setAddressCursor(value);

        if (value === 'add') {
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
            return
        }

        const address = data.find(e => e.id === parseInt(value));

        if (address) {
            setFormData({
                name: address.name || '',
                address_street: address.address_street || '',
                address_city: address.address_city || '',
                address_country: address.address_country || '',
                address_zip_code: address.address_zip_code || ''
            })
        }
    };

    const deleteAddress = async () => {
        const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}/${selectValue}`, {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${user.token}` }
        });
        const res = await req.json();

        if (req.status !== 200) {
            navigate('/error');
            return
        }

        if (res) {
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
            setAddressCursor('null');
            setUserProfile(prev => {
                return { ...prev, delivery_address: res }
            })

            setMsgConf(trad.conf_delete_address[lang]);
            setTimeout(() => { setMsgConf('') }, 2500)
            return
        }

        setMsgConf(trad.error_general[lang]);
        setTimeout(() => { setMsgConf('') }, 2500)
    }

    const updateAddress = async () => {
        const fields = [
            'name',
            'address_street',
            'address_city',
            'address_country',
            'address_zip_code',
        ];

        const check = checkFormData(formData, setAlertFields, fields);

        if (!check) {
            setTimeout(() => {
                setAlertFields({
                    name: false,
                    address_street: false,
                    address_city: false,
                    address_country: false,
                    address_zip_code: false,
                })
            }, 2000)
            return
        }

        const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}/${selectValue}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
        const res = await req.json();

        if (req.status !== 200) {
            navigate('/error');
            return
        }

        if (res) {
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
            setAddressCursor('null');
            setUserProfile(prev => {
                return { ...prev, delivery_address: res }
            })

            setMsgConf(trad.conf_update_address[lang]);
            setTimeout(() => { setMsgConf('') }, 2500);
            return
        }

        setMsgConf(trad.error_general[lang]);
        setTimeout(() => { setMsgConf('') }, 2500)
    }

    const addAddress = async () => {
        const fields = [
            'name',
            'address_street',
            'address_city',
            'address_country',
            'address_zip_code',
        ];

        const check = checkFormData(formData, setAlertFields, fields);

        if (!check) {
            setTimeout(() => {
                setAlertFields({
                    name: false,
                    address_street: false,
                    address_city: false,
                    address_country: false,
                    address_zip_code: false,
                })
            }, 2000)
            return
        }

        const req = await fetch(`https://api.pro-vide.eu/user/address/${user._id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${user.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
        const res = await req.json();

        if (req.status !== 201) {
            navigate('/error');
            return
        }

        if (res) {
            setFormData({
                name: '',
                address_street: '',
                address_city: '',
                address_country: '',
                address_zip_code: ''
            })
            setAddressCursor('null');
            setUserProfile(prev => {
                return { ...prev, delivery_address: res }
            })

            setMsgConf(trad.conf_add_address[lang]);
            setTimeout(() => { setMsgConf('') }, 2500);
            return
        }

        setMsgConf(trad.error_general[lang]);
        setTimeout(() => { setMsgConf('') }, 2500)
    }


    return formData && selectValue && (
        <form className='form_settings form_address'>
            <h3>
                <img src={icon} alt="Icon" />
                {trad.title_form_addresses[lang]}
            </h3>

            <div className="address_selector">
                <label>{trad.label_address_selector[lang]}</label>

                <select value={selectValue} onChange={handleSelectChange}>
                    <option value="null">{trad.address_selector_default[lang]}</option>
                    <option value="add">{trad.address_selector_add[lang]}</option>

                    {data.length !== 0 && data.map((address, idx) =>
                        <option
                            key={idx}
                            value={address.id}
                        >
                            {address.name}, {address?.address_street}, {address?.address_city} {address?.address_country}
                        </option>
                    )}
                </select>
            </div>

            {selectValue !== 'null' &&
                <div className="container_inp">
                    <Input
                        className='settings'
                        subClass={alertFields.name}
                        label={trad.label_address_name[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_address_name[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel='name'
                    />

                    <i className="invisible"></i>

                    <Input
                        className='settings'
                        subClass={alertFields.address_street}
                        label={trad.label_address[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_address[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel='address_street'
                    />

                    <Input
                        className='settings'
                        subClass={alertFields.address_city}
                        label={trad.label_city[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_city[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel='address_city'
                    />

                    <Input
                        className='settings'
                        subClass={alertFields.address_country}
                        label={trad.label_country[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_country[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel='address_country'
                    />

                    <Input
                        className='settings'
                        subClass={alertFields.address_zip_code}
                        label={trad.label_zipcode[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_zipcode[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel='address_zip_code'
                    />
                </div>
            }

            {selectValue !== 'null' &&
                <div className="panel">
                    {selectValue !== 'add' && <button type='button' onClick={() => deleteAddress()} className='btn_red'>{trad.delete[lang]}</button>}
                    {selectValue !== 'add' && <button type='button' onClick={() => updateAddress()} className='btn_blue'>{trad.update[lang]}</button>}
                    {selectValue === 'add' && <button type='button' onClick={() => addAddress()} className='btn_blue'>{trad.add[lang]}</button>}
                </div>
            }

            {msgConf && msgConf !== '' &&
                <div className="msg_conf">
                    <div className="content">
                        <p>{msgConf}</p>
                    </div>
                </div>
            }
        </form>
    )
}

export default FormAddress;