import { configureStore, createSlice } from "@reduxjs/toolkit";

// ------------------------------------------------------------------------------------------------
// State utilisateur

const userSlice = createSlice({
    name: "user",
    initialState: {
        lastname: '',
        firstname: '',
        password: '',
        _id: '',
        token: ''
    },
    reducers: {
        login: (state, action) => {
            const { lastname, firstname, password, _id, token } = action.payload;
            state.lastname = lastname || '';
            state.firstname = firstname || '';
            state.password = password || '';
            state.token = token || '';
            state._id = _id || '';
        },
        logout: (state, action) => {
            state.lastname = '';
            state.firstname = '';
            state.password = '';
            state.token = '';
            state._id = '';
        }
    }
})

export const { login, logout } = userSlice.actions;

// ------------------------------------------------------------------------------------------------
// State menu

const menuSlice = createSlice({
    name: "menu",
    initialState: {
        nav_menu: 0,
        nav_type: 1,
        menu_desktop: false,
        menu_mobile: false,
        user_menu: false,
        loading: 0,
        lock: false,
        lang: 'fr'
    },
    reducers: {
        open: (state, action) => {
            if (action.payload === 'menu_desktop') {
                state.menu_desktop = !state.menu_desktop;
                state.menu_mobile = false;
                state.user_menu = false;
            }
            if (action.payload === 'menu_mobile') {
                state.menu_desktop = false;
                state.menu_mobile = !state.menu_mobile;
                state.user_menu = false;
            }
            if (action.payload === 'user_menu') {
                state.menu_desktop = false;
                state.menu_mobile = false;
                state.user_menu = !state.user_menu;
            }

            if (state.menu_desktop || state.menu_mobile || state.user_menu) {
                state.lock = true;
            }
            else {
                state.lock = false
            }
        },
        close: (state, action) => {
            state.menu_desktop = false;
            state.menu_mobile = false;
            state.user_menu = false;
            state.nav_menu = 0;
            state.lock = false;
        },
        set_menu: (state, action) => {
            if (state.nav_type === 1) {
                state.menu_desktop = state.nav_menu !== action.payload;
            }
            state.nav_menu = state.nav_menu === action.payload ? 0 : action.payload;
        },
        set_nav: (state, action) => {
            state.nav_type = action.payload;
        },
        set_lang: (state, action) => {
            if (state.lang !== action.payload) {
                state.lang = action.payload;
            }
        },
        start_loading: (state, action) => {
            state.loading = state.loading + 1;

        },
        end_loading: (state, action) => {
            state.loading = state.loading - 1;

        }
    }
})

export const { open, close, set_menu, set_nav, set_lang, start_loading, end_loading } = menuSlice.actions;

// ------------------------------------------------------------------------------------------------
// State des données

const dataSlice = createSlice({
    name: "data",
    initialState: {
        categories: [],
        products: [],
        services: [],
        achievements: [],
    },
    reducers: {
        addCategoriesToState: (state, action) => {
            state.categories = action.payload;
        },
        addProductsToState: (state, action) => {
            state.products = action.payload;
        },
        addServicesToState: (state, action) => {
            state.services = action.payload;
        },
        addAchievementsToState: (state, action) => {
            state.achievements = action.payload;
        }
    }
})

export const { addCategoriesToState, addProductsToState, addServicesToState, addAchievementsToState } = dataSlice.actions;

// ------------------------------------------------------------------------------------------------
// State trad

const translateSlice = createSlice({
    name: "trad",
    initialState: {
        sub_title_website: {
            fr: `Créateur de solutions`,
            en: `Solutions Creator`,
            sp: `Creador de soluciones`,
            de: `Lösungserstellers`
        },
        descrip_website: {
            fr: `Nous imaginons, concevons et réalisons des accessoires et équipements sous vide qui répondent à vos besoins`,
            en: `We imagine, design and produce vacuum accessories and equipments that meet your needs`,
            sp: `Imaginamos, diseñamos y producimos accesorios y equipos de vacío que satisfagan sus necesidades.`,
            de: `Wir konzipieren, entwerfen und produzieren Vakuumzubehör und -geräte, die Ihren Anforderungen entsprechen`
        },
        btn_menu_prod: {
            fr: `Produits`,
            en: `Products`,
            sp: `Productos`,
            de: `Produkte`
        },
        btn_menu_serv: {
            fr: `Services`,
            en: `Services`,
            sp: `Servicios`,
            de: `Dienstleistungen`
        },
        btn_page_achievements: {
            fr: `Réalisations`,
            en: `Achievements`,
            sp: `Logros`,
            de: `Erfolge`
        },
        btn_page_contact: {
            fr: `Contact`,
            en: `Contact`,
            sp: `Contactar`,
            de: `Kontakt`
        },
        btn_page_about: {
            fr: `À propos`,
            en: `About`,
            sp: `A proposito`,
            de: `Um`
        },
        btn_page_login: {
            fr: `Se connecter`,
            en: `Login`,
            sp: `Conectarse`,
            de: `Einloggen`
        },
        btn_page_settings: {
            fr: `Paramètre de compte`,
            en: `Account setting`,
            sp: `Configuración de cuenta`,
            de: `Konto-Einstellung`
        },
        btn_page_cart: {
            fr: `Consulter mon panier`,
            en: `View my cart`,
            sp: `Ver mi carrito`,
            de: `Einkaufswagen`
        },
        btn_page_history: {
            fr: `Historique des demandes`,
            en: `Request history`,
            sp: `Historial de solicitudes`,
            de: `Anfrageverlauf`
        },
        btn_page_cgu: {
            fr: `Conditions Générales d’Utilisation`,
            en: `Terms of Use`,
            sp: `Términos de uso`,
            de: `Nutzungsbedingungen`
        },
        btn_page_pc: {
            fr: `Politique de confidentialité`,
            en: `Privacy Policy`,
            sp: `Política de privacidad`,
            de: `Datenschutzrichtlinie`
        },
        btn_logout: {
            fr: `Se déconnecter`,
            en: `Sign out`,
            sp: `Desconectarse`,
            de: `Abmelden`
        },
        btn_valid: {
            fr: `Valider`,
            en: `Validate`,
            sp: `Validar`,
            de: `Bestätigen`
        },
        btn_show_more: {
            fr: `Voir plus`,
            en: `Show more`,
            sp: `Ver más`,
            de: `Mehr anzeigen`
        },
        title_menu_prod: {
            fr: `Nos produits`,
            en: `Our products`,
            sp: `Nuestros productos`,
            de: `Unsere Produkte`
        },
        title_menu_serv: {
            fr: `Nos services`,
            en: `Our services`,
            sp: `Nuestros servicios`,
            de: `Unsere Dienstleistungen`
        },
        title_compagny_footer: {
            fr: `Entreprise`,
            en: `Company`,
            sp: `Empresa`,
            de: `Unternehmen`
        },
        title_account_footer: {
            fr: `Mon compte`,
            en: `My Account`,
            sp: `Mi cuenta`,
            de: `Mein Konto`
        },
        title_legale_notices_footer: {
            fr: `Mentions légales`,
            en: `Legal Notice`,
            sp: `Aviso legal`,
            de: `Impressum`
        },
        title_location_footer: {
            fr: `Nous retrouver`,
            en: `Find Us`,
            sp: `Encuéntranos`,
            de: `Finden Sie uns`
        },
        text_license_footer: {
            fr: `Tous droits réservés (c) 2024 Pro-Vide`,
            en: `All rights reserved (c) 2024 Pro-Vide`,
            sp: `Todos los derechos reservados (c) 2024 Pro-Vide`,
            de: `Alle Rechte vorbehalten (c) 2024 Pro-Vide`
        },
        title_page_login: {
            fr: `Espace client`,
            en: `Customer Area`,
            sp: `Área de cliente`,
            de: `Kundenbereich`
        },
        title_comment_login: {
            fr: `En créant votre compte, vous pourrez demander un devis,
            obtenir l'historique de vos demandes, suivre vos commandes...`,
            en: `By creating your account, you can request a quote, 
            get the history of your requests, track your orders...`,
            sp: `Al crear su cuenta, podrá solicitar un presupuesto,
            obtener el historial de sus solicitudes, seguir sus pedidos...`,
            de: `Durch die Erstellung Ihres Kontos können Sie ein Angebot anfordern,
            den Verlauf Ihrer Anfragen einsehen, Ihre Bestellungen verfolgen...`
        },
        title_form_login: {
            fr: `Déjà client ?`,
            en: `Already a customer?`,
            sp: `¿Ya es cliente?`,
            de: `Schon Kunde?`
        },
        title_form_signup: {
            fr: `Pas encore inscrit ?`,
            en: `Not registered yet?`,
            sp: `¿Aún no está registrado?`,
            de: `Noch nicht registriert?`
        },
        label_company: {
            fr: `Société`,
            en: `Compagny`,
            sp: `Unternehmen`,
            de: `Empresa`
        },
        label_service: {
            fr: `Service`,
            en: `Service`,
            sp: `Servicio`,
            de: `Service`
        },
        label_siret: {
            fr: `Siret/Siren`,
            en: `Siret/Siren`,
            sp: `Siret/Siren`,
            de: `Siret/Siren`
        },
        label_lastname: {
            fr: `Nom`,
            en: `Lastname`,
            sp: `Apellido`,
            de: `Nachname`
        },
        label_firstname: {
            fr: `Prénom`,
            en: `Firstname`,
            sp: `Nombre de pila`,
            de: `Vorname`
        },
        label_email: {
            fr: `Adresse mail`,
            en: `Mail address`,
            sp: `Correo electrónico`,
            de: `Mail adresse`
        },
        label_password: {
            fr: `Mot de passe`,
            en: `Password`,
            sp: `Contraseña`,
            de: `Passwort`
        },
        label_phone: {
            fr: `Téléphone mobile`,
            en: `Mobile phone`,
            sp: `Teléfono móvil`,
            de: `Mobiltelefon`
        },
        label_cellphone: {
            fr: `Téléphone fixe`,
            en: `Cellphone`,
            sp: `Teléfono fijo`,
            de: `Telefon`
        },
        label_phonenumber: {
            fr: `Téléphone`,
            en: `Phonenumber`,
            sp: `Teléfono`,
            de: `Telefon`
        },
        label_address: {
            fr: `Adresse`,
            en: `Address`,
            sp: `Dirección`,
            de: `Adresse`
        },
        label_city: {
            fr: `Ville`,
            en: `City`,
            sp: `Ciudad`,
            de: `Stadt`
        },
        label_zipcode: {
            fr: `Code postal`,
            en: `Zip code`,
            sp: `Código postal`,
            de: `Postleitzahl`
        },
        label_country: {
            fr: `Pays`,
            en: `Country`,
            sp: `País`,
            de: `Land`
        },
        placeholder_inp_company: {
            fr: `Saisissez le nom de votre société`,
            en: `Enter your company name`,
            sp: `Ingrese el nombre de su empresa`,
            de: `Geben Sie den Namen Ihres Unternehmens ein`
        },
        placeholder_inp_service: {
            fr: `Saisissez le nom du service`,
            en: `Enter the name of the service`,
            sp: `Ingrese el nombre del servicio`,
            de: `Geben Sie den Namen des Dienstes ein`
        },
        placeholder_inp_siret: {
            fr: `Saisissez votre numero siret/siren`,
            en: `Enter your Siret/Siren number`,
            sp: `Ingrese su número de Siret/Siren`,
            de: `Geben Sie Ihre Siret/Siren-Nummer ein`
        },
        placeholder_inp_lastname: {
            fr: `Saisissez votre nom`,
            en: `Enter your last name`,
            sp: `Ingrese su apellido`,
            de: `Geben Sie Ihren Nachnamen ein`
        },
        placeholder_inp_firstname: {
            fr: `Saisissez votre prénom`,
            en: `Enter your first name`,
            sp: `Ingrese su nombre de pila`,
            de: `Geben Sie Ihren Vornamen ein`
        },
        placeholder_inp_email: {
            fr: `Saisissez votre adresse mail`,
            en: `Enter your mail address`,
            sp: `Ingrese su correo electrónico`,
            de: `Geben Sie Ihre Mailadresse ein`
        },
        placeholder_inp_password: {
            fr: `Saisissez votre mot de passe`,
            en: `Enter your password`,
            sp: `Ingrese su contraseña`,
            de: `Geben Sie Ihr Passwort ein`
        },
        placeholder_inp_newpassword: {
            fr: `Saisissez votre nouveau mot de passe`,
            en: `Enter your new password`,
            sp: `Ingrese su nueva contraseña`,
            de: `Geben Sie Ihr neues Passwort ein`
        },
        placeholder_inp_phone: {
            fr: `Saisissez votre mobile`,
            en: `Enter your mobile number`,
            sp: `Ingrese su teléfono móvil`,
            de: `Geben Sie Ihre Handynummer ein`
        },
        placeholder_inp_cellphone: {
            fr: `Saisissez votre fixe`,
            en: `Enter your phone number`,
            sp: `Ingrese su teléfono fijo`,
            de: `Geben Sie Ihre Telefonnummer ein`
        },
        placeholder_inp_address: {
            fr: `Saisissez votre adresse`,
            en: `Enter your address`,
            sp: `Ingrese su dirección`,
            de: `Geben Sie Ihre Adresse ein`
        },
        placeholder_inp_city: {
            fr: `Saisissez votre ville`,
            en: `Enter your city`,
            sp: `Ingrese su ciudad`,
            de: `Geben Sie Ihre Stadt ein`
        },
        placeholder_inp_zipcode: {
            fr: `96000`,
            en: `SW1 1DF`,
            sp: `28001`,
            de: `10115`
        },
        placeholder_inp_country: {
            fr: `Saisissez votre pays`,
            en: `Enter your country`,
            sp: `Ingrese su país`,
            de: `Geben Sie Ihr Land ein`
        },
        check_remember_my: {
            fr: `Se souvenir de moi`,
            en: `Remember me`,
            sp: `Recuérdame`,
            de: `Erinnere dich an mich`
        },
        check_cond_user: {
            fr: `J’accepte les conditions générales d’utilisations`,
            en: `I accept the terms and conditions`,
            sp: `Acepto los términos y condiciones`,
            de: `Ich akzeptiere die Allgemeinen Geschäftsbedingungen`
        },
        msg_optional: {
            fr: `* : Optionnel`,
            en: `* : Optional`,
            sp: `* : Opcional`,
            de: `* : Optional`
        },
        error_server: {
            fr: `(Erreur 500) Problème de serveur, veuillez nous excuser pour ce désagrément. Nous vous invitons à réessayer ultérieurement. Si le problème persiste, merci de contacter notre support.`,
            en: `(Error 500) Server problem, we apologize for any inconvenience. We invite you to try again later. If the problem persists, please contact our support.`,
            sp: `(Error 500) Problema de servidor, disculpe las molestias. Le invitamos a intentarlo de nuevo más tarde. Si el problema persiste, comuníquese con nuestro soporte.`,
            de: `(Fehler 500) Serverproblem, wir entschuldigen uns für die Unannehmlichkeiten. Wir laden Sie ein, es später noch einmal zu versuchen. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unseren Support.`
        },
        error_email_or_pw: {
            fr: `Email ou mot de passe incorrecte`,
            en: `Email or password invalid`,
            sp: `Correo electrónico o contraseña inválida`,
            de: `E-Mail oder ungültiges Passwort`
        },
        error_email_invalid: {
            fr: `Email incorrecte`,
            en: `Email invalid`,
            sp: `Correo electrónico inválido`,
            de: `Ungültige E-Mail`
        },
        error_user_already_registered: {
            fr: `Utilisateur déja enregistré`,
            en: `User already registered`,
            sp: `Usuario ya registrado`,
            de: `Benutzer bereits registriert`
        },
        error_user_not_found: {
            fr: `Utilisateur inexistant`,
            en: `User not found`,
            sp: `Usuario no encontrado`,
            de: `Benutzer nicht gefunden`
        },
        error_pw_invalid: {
            fr: `Mot de passe invalide`,
            en: `Invalid password`,
            sp: `Contraseña inválida`,
            de: `Ungültiges Passwort`
        },
        error_data_invalid: {
            fr: `Données invalides`,
            en: `Invalid data`,
            sp: `Datos inválidos`,
            de: `Ungültige Daten`
        },
        error_signup: {
            fr: `Verifiez vos informations d'inscription. Si un champ n'a pas de * alors ce dernier est obligatoire.`,
            en: `Please check your registration information. If a field does not have a * then it is mandatory.`,
            sp: `Verifique su información de registro. Si un campo no tiene un * entonces es obligatorio.`,
            de: `Bitte überprüfen Sie Ihre Registrierungsinformationen. Wenn ein Feld kein * hat, dann ist es obligatorisch.`
        },
        btn_forget_account: {
            fr: `Mot de passe oublié`,
            en: `Forgot Password`,
            sp: `¿Olvidó su contraseña?`,
            de: `Passwort vergessen`
        },
        form_login_btn: {
            fr: `Connexion`,
            en: `Login`,
            sp: `Iniciar sesión`,
            de: `Einloggen`
        },
        form_signup_btn: {
            fr: `Inscription`,
            en: `Sign up`,
            sp: `Registrarse`,
            de: `Registrieren`
        },
        title_mdl_signup: {
            fr: `Confirmation d'inscription`,
            en: `Registration Confirmation`,
            sp: `Confirmación de Registro`,
            de: `Registrierungsbestätigung`
        },
        text_mdl_signup: {
            fr: `Chère client(e), afin de finaliser votre inscription, un code vous a été envoyé par mail. Merci de le renseigner ci-dessous :`,
            en: `Dear customer, to complete your registration, a code has been sent to your email. Please enter it below :`,
            sp: `Estimado/a cliente, para completar su registro, se le ha enviado un código por correo electrónico. Por favor, ingréselo a continuación :`,
            de: `Sehr geehrte/r Kundin/Kunde, um Ihre Registrierung abzuschließen, wurde Ihnen ein Code per E-Mail gesendet. Bitte geben Sie ihn unten ein :`
        },
        btn_resend_code: {
            fr: `Je n'ai pas reçu de code ?`,
            en: `Didn't receive the code?`,
            sp: `¿No recibí el código?`,
            de: `Code nicht erhalten?`
        },
        msg_resend_code: {
            fr: `Un nouveau code vient d'être renvoyé à `,
            en: `A new code has been sent to `,
            sp: `Se ha enviado un nuevo código a `,
            de: `Ein neuer Code wurde gesendet an `
        },
        error_code_invalid: {
            fr: `Erreur, le code est invalide`,
            en: `Error, the code is invalid`,
            sp: `Error, el código es inválido`,
            de: `Fehler, der Code ist ungültig`
        },
        error_send_code: {
            fr: `Erreur, impossible d'envoyer le code`,
            en: `Error, unable to send the code`,
            sp: `Error, no se puede enviar el código`,
            de: `Fehler, der Code kann nicht gesendet werden`
        },
        msg_counter_code: {
            fr: ` tentatives restantes`,
            en: ` attempts remaining`,
            sp: ` intentos restantes`,
            de: ` Versuche übrig`
        },
        mdl_pw_forget_title_step1: {
            fr: `Récupérer mon compte`,
            en: `Recover my account`,
            sp: `Recuperar mi cuenta`,
            de: `Mein Konto wiederherstellen`
        },
        mdl_pw_forget_text_step1: {
            fr: `Afin de pouvoir vous reconnecter en cas d'oubli de votre mot de passe, nous vous invitons à renseigner votre adresse mail ci-dessous :`,
            en: `To be able to reconnect you in case you forget your password, please enter your email address below:`,
            sp: `Para poder reconectarte en caso de que olvides tu contraseña, te invitamos a ingresar tu dirección de correo electrónico a continuación:`,
            de: `Um Sie wieder verbinden zu können, falls Sie Ihr Passwort vergessen haben, geben Sie bitte Ihre E-Mail-Adresse unten ein:`
        },
        mdl_pw_forget_title_step2: {
            fr: `Vérification`,
            en: `Verification`,
            sp: `Verificación`,
            de: `Verifizierung`
        },
        mdl_pw_forget_text_step2: {
            fr: `Votre compte a bien été retrouvé, afin de nous assurer qu'il s'agisse bien de votre compte, nous vous invitons à renseigner le code qui vous a été envoyé par mail :`,
            en: `Your account has been found. To ensure that it is indeed your account, please enter the code that was sent to you by email:`,
            sp: `Su cuenta ha sido encontrada. Para asegurarnos de que es realmente su cuenta, le invitamos a ingresar el código que se le envió por correo electrónico:`,
            de: `Ihr Konto wurde gefunden. Um sicherzustellen, dass es sich tatsächlich um Ihr Konto handelt, geben Sie bitte den Code ein, der Ihnen per E-Mail zugesendet wurde:`
        },
        mdl_pw_forget_title_step3: {
            fr: `Mise à jour du mot de passe`,
            en: `Update password`,
            sp: `Actualizar contraseña`,
            de: `Passwort aktualisieren`
        },
        mdl_pw_forget_text_step3: {
            fr: `Parfait, il ne vous reste plus qu'à mettre à jour votre mot de passe :`,
            en: `Perfect, all you need to do now is update your password:`,
            sp: `Perfecto, solo necesitas actualizar tu contraseña:`,
            de: `Perfekt, Sie müssen nur noch Ihr Passwort aktualisieren:`
        },
        btn_show_reference: {
            fr: `Nos références`,
            en: `Our references`,
            sp: `Nuestras referencias`,
            de: `Unsere Referenzen`
        },
        btn_make_request: {
            fr: `Faire une demande`,
            en: `Make a request`,
            sp: `Hacer una solicitud`,
            de: `Eine Anfrage stellen`
        },
        text_home: {
            fr: `Accueil`,
            en: `Home`,
            sp: `Inicio`,
            de: `Startseite`
        },
        onglet_table_ref: {
            fr: `Nos références`,
            en: `Our references`,
            sp: `Nuestras referencias`,
            de: `Unsere Referenzen`
        },
        onglet_description: {
            fr: `Description`,
            en: `Description`,
            sp: `Descripción`,
            de: `Beschreibung`
        },
        onglet_downloads_files: {
            fr: `Téléchargements`,
            en: `Downloads`,
            sp: `Descargas`,
            de: `Downloads`
        },
        onglet_prod_associated: {
            fr: `Produits associés`,
            en: `Associated products`,
            sp: `Productos asociados`,
            de: `Zugehörige Produkte`
        },
        title_location: {
            fr: `Où nous trouver?`,
            en: `Where to find us?`,
            sp: `¿Dónde encontrarnos?`,
            de: `Wo finden Sie uns?`
        },
        title_form_contact: {
            fr: `Nous contacter`,
            en: `Contact us`,
            sp: `Contáctenos`,
            de: `Kontaktiere uns`
        },
        label_name: {
            fr: `Nom complet`,
            en: `Full name`,
            sp: `Nombre completo`,
            de: `Vollständiger Name`
        },
        label_request_contact: {
            fr: `Demande`,
            en: `Request`,
            sp: `Pedido`,
            de: `Anfrage`
        },
        placeholder_request_contact: {
            fr: `Donnez-nous un maximum de détails pour nous permettre de répondre au mieux à votre demande.`,
            en: `Give us as much detail as possible to enable us to best respond to your request.`,
            sp: `Bríndenos tantos detalles como sea posible para que podamos responder mejor a su solicitud.`,
            de: `Geben Sie uns so viele Details wie möglich, damit wir optimal auf Ihre Anfrage reagieren können.`
        },
        label_input_file: {
            fr: `Fichier`,
            en: `File`,
            sp: `Archivo`,
            de: `Datei`
        },
        placeholder_input_file: {
            fr: `Ajouter un fichier`,
            en: `Add file`,
            sp: `Agregar un archivo`,
            de: `Fügen Sie eine Datei hinzu`
        },
        button_form_contact: {
            fr: `Envoyer`,
            en: `Send`,
            sp: `Enviar a`,
            de: `Schicken`
        },
        title_achievement: {
            fr: `Nos réalisations`,
            en: `Our achievements`,
            sp: `Nuestros logros`,
            de: `Unsere Erfolge`
        },
        descrip_achievement: {
            fr: `Pro-Vide, votre partenaire d'excellence dans le domaine de l'usinage de précision. Forts d'une expertise inégalée, nous sommes spécialisés dans la fabrication de pièces sur mesure.
        
        Nos Points Forts :
        
        Usinage de Précision : Nous transformons les idées en réalité avec une précision inégalée. Nos équipements de pointe et notre équipe qualifiée garantissent des pièces conformes aux exigences les plus strictes.
        
        Polyvalence : De la pièce unitaire à la moyenne série, notre capacité de production flexible nous permet de répondre efficacement à vos besoins, qu'il s'agisse de petites séries spécialisées ou de projets de plus grande envergure.`,
            en: `Pro-Vide, your partner of excellence in the field of precision machining. With unrivaled expertise, we specialize in the manufacturing of custom parts.
        
        Our strong points :
        
        Precision Machining: We turn ideas into reality with unparalleled precision. Our cutting-edge equipment and qualified team guarantee parts that meet the strictest requirements.
        
        Versatility: From single parts to medium series, our flexible production capacity allows us to respond effectively to your needs, whether they are small specialized series or larger projects.`,
            sp: `Pro-Vide, su socio de excelencia en el campo del mecanizado de precisión. Con una experiencia inigualable, nos especializamos en la fabricación de piezas personalizadas.
        
        Nuestros puntos fuertes:
        
        Mecanizado de Precisión: Convertimos ideas en realidad con una precisión incomparable. Nuestros equipos de última generación y nuestro equipo calificado garantizan piezas que cumplen con los requisitos más estrictos.
        
        Versatilidad: Desde piezas individuales hasta series medianas, nuestra capacidad de producción flexible nos permite responder eficazmente a sus necesidades, ya sean pequeñas series especializadas o proyectos de mayor envergadura.`,
            de: `Pro-Vide, Ihr Exzellenzpartner im Bereich Präzisionsbearbeitung. Mit unübertroffenem Fachwissen sind wir auf die Herstellung kundenspezifischer Teile spezialisiert.
        
        Unsere Stärken:
        
        Präzisionsbearbeitung: Wir setzen Ideen mit beispielloser Präzision in die Realität um. Unsere hochmoderne Ausrüstung und unser qualifiziertes Team garantieren Teile, die den strengsten Anforderungen entsprechen.
        
        Vielseitigkeit: Vom Einzelteil bis zur Mittelserie – unsere flexible Produktionskapazität ermöglicht es uns, effektiv auf Ihre Bedürfnisse einzugehen, egal ob kleine Spezialserien oder größere Projekte.`
        },
        filters: {
            fr: `Filtres `,
            en: `Filters `,
            sp: `Filtros `,
            de: `Filter `
        },
        all: {
            fr: `Toutes`,
            en: `All`,
            sp: `Todas`,
            de: `Alle`
        },
        close: {
            fr: `Fermer`,
            en: `Close`,
            sp: `Cerrar`,
            de: `Schließen`
        },
        edit: {
            fr: `Modifier`,
            en: `Edit`,
            sp: `Editar`,
            de: `Bearbeiten`
        },
        cancel: {
            fr: `Annuler`,
            en: `Cancel`,
            sp: `Cancelar`,
            de: `Abbrechen`
        },
        title_settings: {
            fr: `Paramètres de compte`,
            en: `Account Settings`,
            sp: `Configuración de la cuenta`,
            de: `Kontoeinstellungen`
        },
        title_form_coordinates: {
            fr: `Coordonnées`,
            en: `Contact Information`,
            sp: `Datos de contacto`,
            de: `Kontaktdaten`
        },
        title_form_addresses: {
            fr: `Adresses`,
            en: `Addresses`,
            sp: `Direcciones`,
            de: `Adressen`
        },
        label_address_selector: {
            fr: `Vos adresses :`,
            en: `Your addresses:`,
            sp: `Sus direcciones:`,
            de: `Ihre Adressen:`
        },
        address_selector_default: {
            fr: `Sélectionner une adresse`,
            en: `Select an address`,
            sp: `Seleccionar una dirección`,
            de: `Adresse auswählen`
        },
        address_selector_add: {
            fr: `+ Ajouter une adresse`,
            en: `+ Add an address`,
            sp: `+ Añadir una dirección`,
            de: `+ Adresse hinzufügen`
        },
        label_address_name: {
            fr: `Nom`,
            en: `Name`,
            sp: `Nombre`,
            de: `Name`
        },
        placeholder_inp_address_name: {
            fr: `Nom de l'adresse`,
            en: `Address Name`,
            sp: `Nombre de la dirección`,
            de: `Adressname`
        },
        title_form_address_mail: {
            fr: `Adresse mail`,
            en: `Email address`,
            sp: `Dirección de correo electrónico`,
            de: `E-Mail-Adresse`
        },
        btn_edit_email: {
            fr: `Modifier mon email`,
            en: `Edit my email`,
            sp: `Editar mi correo electrónico`,
            de: `Meine E-Mail-Adresse bearbeiten`
        },
        label_new_email: {
            fr: `Nouvelle adresse mail`,
            en: `New email address`,
            sp: `Nueva dirección de correo electrónico`,
            de: `Neue E-Mail-Adresse`
        },
        placeholder_inp_new_email: {
            fr: `Saisissez votre nouvelle adresse mail`,
            en: ``,
            sp: ``,
            de: ``
        },
        text_mdl_update_email: {
            fr: `Chère client(e), afin de finaliser la mise à jour de votre adresse mail, un code vous a été envoyé à votre nouvelle adresse mail. Merci de le renseigner ci-dessous :`,
            en: `Dear customer, to complete the update of your email address, a code has been sent to your new email address. Please enter it below:`,
            sp: `Estimado/a cliente, para completar la actualización de su dirección de correo electrónico, se le ha enviado un código a su nueva dirección de correo electrónico. Por favor, ingréselo a continuación:`,
            de: `Sehr geehrte/r Kundin/Kunde, um die Aktualisierung Ihrer E-Mail-Adresse abzuschließen, wurde Ihnen ein Code an Ihre neue E-Mail-Adresse gesendet. Bitte geben Sie ihn unten ein:`
        },
        title_form_password: {
            fr: `Mot de passe`,
            en: `Password`,
            sp: `Contraseña`,
            de: `Passwort`
        },
        btn_edit_password: {
            fr: `Modifier mon mot de passe`,
            en: `Change my password`,
            sp: `Cambiar mi contraseña`,
            de: `Mein Passwort ändern`
        },
        label_new_pw: {
            fr: `Nouveau mot de passe`,
            en: `New password`,
            sp: `Nueva contraseña`,
            de: `Neues Passwort`
        },
        placeholder_inp_new_pw: {
            fr: `Saisissez votre mot de passe`,
            en: `Enter your password`,
            sp: `Ingrese su contraseña`,
            de: `Geben Sie Ihr Passwort ein`
        },
        label_conf_new_pw: {
            fr: `Confirmation du mot de passe`,
            en: `Confirm password`,
            sp: `Confirmación de la contraseña`,
            de: `Passwort bestätigen`
        },
        placeholder_inp_conf_new_pw: {
            fr: `Confirmer votre mot de passe`,
            en: `Confirm your password`,
            sp: `Confirme su contraseña`,
            de: `Bestätigen Sie Ihr Passwort`
        },
        confirmation: {
            fr: `Confirmation`,
            en: `Confirmation`,
            sp: `Confirmación`,
            de: `Bestätigung`
        },
        confirm_with_password: {
            fr: `Afin de confirmer votre demande, veuillez renseigner votre mot de passe :`,
            en: `To confirm your request, please enter your password:`,
            sp: `Para confirmar su solicitud, por favor ingrese su contraseña:`,
            de: `Um Ihre Anfrage zu bestätigen, geben Sie bitte Ihr Passwort ein:`
        },
        msg_confirm_user_update: {
            fr: `Vos coordonnées ont bien été mises à jour`,
            en: `Your contact information has been successfully updated`,
            sp: `Sus datos de contacto han sido actualizados correctamente`,
            de: `Ihre Kontaktdaten wurden erfolgreich aktualisiert`
        },
        add: {
            fr: `Ajouter`,
            en: `Add`,
            sp: `Añadir`,
            de: `Hinzufügen`
        },
        update: {
            fr: `Modifier`,
            en: `Update`,
            sp: `Actualizar`,
            de: `Aktualisieren`
        },
        delete: {
            fr: `Supprimer`,
            en: `Delete`,
            sp: `Eliminar`,
            de: `Löschen`
        },
        conf_add_address: {
            fr: `Votre adresse a bien été créée`,
            en: `Your address has been successfully created`,
            sp: `Su dirección ha sido creada con éxito`,
            de: `Ihre Adresse wurde erfolgreich erstellt`
        },
        conf_update_address: {
            fr: `Votre adresse a bien été modifiée`,
            en: `Your address has been successfully updated`,
            sp: `Su dirección ha sido actualizada con éxito`,
            de: `Ihre Adresse wurde erfolgreich aktualisiert`
        },
        conf_delete_address: {
            fr: `Votre adresse a bien été supprimée`,
            en: `Your address has been successfully deleted`,
            sp: `Su dirección ha sido eliminada con éxito`,
            de: `Ihre Adresse wurde erfolgreich gelöscht`
        },
        error_general: {
            fr: `Oups... Une erreur est survenue. Si cela persiste, merci de contacter le support.`,
            en: `Oops... An error occurred. If this persists, please contact support.`,
            sp: `Ups... Ha ocurrido un error. Si esto persiste, por favor contacte al soporte.`,
            de: `Hoppla... Ein Fehler ist aufgetreten. Wenn dies weiterhin besteht, kontaktieren Sie bitte den Support.`
        },
        pw_not_identical: {
            fr: `Le mot de passe de confirmation doit être identique à votre nouveau mot de passe`,
            en: `The confirmation password must be identical to your new password`,
            sp: `La contraseña de confirmación debe ser idéntica a su nueva contraseña`,
            de: `Das Bestätigungspasswort muss mit Ihrem neuen Passwort übereinstimmen`
        },
        pw_lenght: {
            fr: `Votre mot de passe doit faire au moins 8 caractères`,
            en: `Your password must be at least 8 characters long`,
            sp: `Su contraseña debe tener al menos 8 caracteres`,
            de: `Ihr Passwort muss mindestens 8 Zeichen lang sein`
        },
        make_a_request: {
            fr: `Faire une demande`,
            en: `Make a request`,
            sp: `Hacer una solicitud`,
            de: `Eine Anfrage stellen`
        },
        msg_bottom_contact: {
            fr: `Si vous désirez une offre, ou de plus amples informations sur ce produit contactez-nous :`,
            en: `If you would like an offer, or more information on this product, contact us :`,
            sp: `Si desea una oferta o más información sobre este producto, contáctenos :`,
            de: `Wenn Sie ein Angebot oder weitere Informationen zu diesem Produkt wünschen, kontaktieren Sie uns :`
        },
        btn_bottom_contact: {
            fr: `Nous écrire`,
            en: `Write us`,
            sp: `Escribenos`,
            de: `Schreiben Sie uns`
        },
        title_msg_add_to_cart: {
            fr: `Panier mis à jour`,
            en: `Cart updated`,
            sp: `Carrito actualizado`,
            de: `Warenkorb aktualisiert`
        },
        msg_add_to_cart: {
            fr: ` ajouté à votre panier`,
            en: ` added to your cart`,
            sp: ` añadido a su carrito`,
            de: ` zu Ihrem Warenkorb hinzugefügt`
        },
        title_cart: {
            fr: `Consultation du panier`,
            en: `Cart Review`,
            sp: `Revisión del carrito`,
            de: `Warenkorbübersicht`
        },
        total_ht: {
            fr: `Total HT :`,
            en: `Total Excl. Tax :`,
            sp: `Total sin IVA :`,
            de: `Gesamt netto :`
        },
        tva: {
            fr: `TVA 20% :`,
            en: `VAT 20% :`,
            sp: `IVA 20% :`,
            de: `MwSt. 20% :`
        },
        total_ttc: {
            fr: `Total TTC :`,
            en: `Total Incl. Tax :`,
            sp: `Total con IVA :`,
            de: `Gesamt brutto :`
        },
        btn_ask_quote: {
            fr: `Demander un devis`,
            en: `Request a Quote`,
            sp: `Solicitar un presupuesto`,
            de: `Angebot anfordern`
        },
        text_contact_cart: {
            fr: `Si vous avez des questions relatives aux produits, n’hésitez pas à nous contacter au 02 35 14 96 58.`,
            en: `If you have any questions about the products, do not hesitate to contact us at 02 35 14 96 58.`,
            sp: `Si tiene alguna pregunta sobre los productos, no dude en contactarnos al 02 35 14 96 58.`,
            de: `Wenn Sie Fragen zu den Produkten haben, zögern Sie nicht, uns unter 02 35 14 96 58 zu kontaktieren.`
        },
        unity_price: {
            fr: `Prix unité`,
            en: `Unit Price`,
            sp: `Precio unitario`,
            de: `Einzelpreis`
        },
        up_ht: {
            fr: `(HT)`,
            en: `(Excl. Tax)`,
            sp: `(sin IVA)`,
            de: `(netto)`
        },
        unity_total: {
            fr: `Total :`,
            en: `Total :`,
            sp: `Total :`,
            de: `Gesamt :`
        },
        unity_quantity: {
            fr: `Qte :`,
            en: `Qty :`,
            sp: `Cant. :`,
            de: `Menge :`
        },
        cart_is_void: {
            fr: `Votre panier est vide`,
            en: `Your cart is empty`,
            sp: `Su carrito está vacío`,
            de: `Ihr Warenkorb ist leer`
        },
        title_general_conditions_of_use: {
            fr: `Conditions Générales d'Utilisation`,
            en: `Terms and Conditions of Use`,
            sp: `Términos y Condiciones de Uso`,
            de: `Allgemeine Nutzungsbedingungen`
        },
        data_general_conditions_of_use: [
            {
                title: {
                    fr: `Acceptation des Conditions Générales d'Utilisation`,
                    en: `Acceptance of Terms and Conditions`,
                    sp: `Aceptación de los Términos y Condiciones`,
                    de: `Akzeptanz der Allgemeinen Geschäftsbedingungen`
                },
                text: {
                    fr: `En accédant et en utilisant ce site web, vous acceptez d'être lié par les présentes Conditions Générales d'Utilisation.`,
                    en: `By accessing and using this website, you agree to be bound by these Terms and Conditions.`,
                    sp: `Al acceder y utilizar este sitio web, acepta estar sujeto a estos Términos y Condiciones.`,
                    de: `Durch den Zugriff auf diese Website und deren Nutzung stimmen Sie diesen Allgemeinen Geschäftsbedingungen zu.`
                }
            },
            {
                title: {
                    fr: `Inscription et Compte Utilisateur`,
                    en: `Registration and User Account`,
                    sp: `Registro y Cuenta de Usuario`,
                    de: `Registrierung und Benutzerkonto`
                },
                text: {
                    fr: `Pour accéder à certaines fonctionnalités de ce site, vous devez créer un compte. En créant un compte, vous acceptez de fournir des informations exactes, à jour et complètes. Vous êtes responsable de la confidentialité de votre mot de passe et de toute activité sur votre compte.`,
                    en: `To access certain features of this site, you must create an account. By creating an account, you agree to provide accurate, up-to-date, and complete information. You are responsible for the confidentiality of your password and all activities on your account.`,
                    sp: `Para acceder a ciertas funciones de este sitio, debe crear una cuenta. Al crear una cuenta, acepta proporcionar información precisa, actualizada y completa. Usted es responsable de la confidencialidad de su contraseña y de todas las actividades en su cuenta.`,
                    de: `Um auf bestimmte Funktionen dieser Website zugreifen zu können, müssen Sie ein Konto erstellen. Durch die Erstellung eines Kontos stimmen Sie zu, genaue, aktuelle und vollständige Informationen bereitzustellen. Sie sind für die Vertraulichkeit Ihres Passworts und alle Aktivitäten auf Ihrem Konto verantwortlich.`
                }
            },
            {
                title: {
                    fr: `Utilisation des Cookies`,
                    en: `Use of Cookies`,
                    sp: `Uso de Cookies`,
                    de: `Verwendung von Cookies`
                },
                text: {
                    fr: `Ce site utilise des cookies pour améliorer votre expérience utilisateur. En utilisant ce site, vous acceptez notre utilisation des cookies conformément à notre Politique de Confidentialité.`,
                    en: `This site uses cookies to improve your user experience. By using this site, you agree to our use of cookies in accordance with our Privacy Policy.`,
                    sp: `Este sitio utiliza cookies para mejorar su experiencia de usuario. Al utilizar este sitio, acepta nuestro uso de cookies de acuerdo con nuestra Política de Cookies.`,
                    de: `Diese Website verwendet Cookies, um Ihre Benutzererfahrung zu verbessern. Durch die Nutzung dieser Website stimmen Sie unserer Verwendung von Cookies gemäß unserer Datenschutzrichtlinie zu.`
                }
            },
            {
                title: {
                    fr: `Demandes de Produits et Services`,
                    en: `Product and Service Requests`,
                    sp: `Solicitudes de Productos y Servicios`,
                    de: `Produkt- und Dienstleistungsanfragen`
                },
                text: {
                    fr: `Les demandes de produits et services effectuées via ce site sont soumises à notre acceptation. Nous nous réservons le droit de refuser toute demande pour quelque raison que ce soit.`,
                    en: `Requests for products and services made through this site are subject to our acceptance. We reserve the right to refuse any request for any reason.`,
                    sp: `Las solicitudes de productos y servicios realizadas a través de este sitio están sujetas a nuestra aceptación. Nos reservamos el derecho de rechazar cualquier solicitud por cualquier motivo.`,
                    de: `Anfragen für Produkte und Dienstleistungen, die über diese Website gestellt werden, unterliegen unserer Annahme. Wir behalten uns das Recht vor, jede Anfrage aus beliebigem Grund abzulehnen.`
                }
            },
            {
                title: {
                    fr: `Panier et Devis`,
                    en: `Cart and Quotes`,
                    sp: `Carrito y Cotizaciones`,
                    de: `Warenkorb und Angebote`
                },
                text: {
                    fr: `Les produits ajoutés au panier ne sont pas réservés et peuvent devenir indisponibles. Les devis générés à partir du panier sont valables pour une durée limitée.`,
                    en: `Products added to the cart are not reserved and may become unavailable. Quotes generated from the cart are valid for a limited time.`,
                    sp: `Los productos añadidos al carrito no están reservados y pueden volverse indisponibles. Las cotizaciones generadas a partir del carrito son válidas por un tiempo limitado.`,
                    de: `Produkte, die dem Warenkorb hinzugefügt wurden, sind nicht reserviert und können nicht verfügbar werden. Angebote, die aus dem Warenkorb erstellt werden, sind nur für eine begrenzte Zeit gültig.`
                }
            },
            {
                title: {
                    fr: `Historique des Demandes et Devis`,
                    en: `Request and Quote History`,
                    sp: `Historial de Solicitudes y Cotizaciones`,
                    de: `Anfragen- und Angebotshistorie`
                },
                text: {
                    fr: `Nous conservons un historique de vos demandes et devis. Vous pouvez y accéder à tout moment dans la section « Historique ».`,
                    en: `We keep a history of your requests and quotes. You can access it at any time in the "History" section.`,
                    sp: `Mantenemos un historial de sus solicitudes y cotizaciones. Puede acceder a él en cualquier momento en la sección "Historial".`,
                    de: `Wir führen eine Historie Ihrer Anfragen und Angebote. Sie können jederzeit im Abschnitt "Historie" darauf zugreifen.`
                }
            },
            {
                title: {
                    fr: `Contact`,
                    en: `Contact`,
                    sp: `Contacto`,
                    de: `Kontakt`
                },
                text: {
                    fr: `Pour toute question ou préoccupation concernant ces Conditions Générales d'Utilisation, veuillez nous contacter via la page « Contact ».`,
                    en: `For any questions or concerns regarding these Terms and Conditions, please contact us via the "Contact" page.`,
                    sp: `Para cualquier pregunta o preocupación sobre estos Términos y Condiciones, por favor contáctenos a través de la página "Contacto".`,
                    de: `Bei Fragen oder Bedenken zu diesen Allgemeinen Geschäftsbedingungen kontaktieren Sie uns bitte über die Seite "Kontakt".`
                }
            },
            {
                title: {
                    fr: `Modification des Conditions Générales d'Utilisation`,
                    en: `Modification of Terms and Conditions`,
                    sp: `Modificación de los Términos y Condiciones`,
                    de: `Änderung der Allgemeinen Geschäftsbedingungen`
                },
                text: {
                    fr: `Nous nous réservons le droit de modifier ces Conditions Générales d'Utilisation à tout moment. Les modifications entreront en vigueur dès leur publication sur ce site.`,
                    en: `We reserve the right to modify these Terms and Conditions at any time. Changes will take effect as soon as they are posted on this site.`,
                    sp: `Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Los cambios entrarán en vigor tan pronto como se publiquen en este sitio.`,
                    de: `Wir behalten uns das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern. Änderungen treten in Kraft, sobald sie auf dieser Website veröffentlicht werden.`
                }
            }
        ],
        title_privacy_policy: {
            fr: `Politique de Confidentialité`,
            en: `Privacy Policy`,
            sp: `Política de Privacidad`,
            de: `Datenschutzrichtlinie`
        },
        data_privacy_policy: [
            {
                title: {
                    fr: `Collecte des Informations`,
                    en: `Information Collection`,
                    sp: `Recopilación de Información`,
                    de: `Datensammlung`
                },
                text: {
                    fr: `Nous recueillons des informations lorsque vous vous inscrivez sur notre site. Les informations recueillies incluent votre nom, prénom, nom de votre société, service, numéro de SIRET/SIREN, adresse e-mail, numéro de téléphone fixe et mobile, adresse postale, ville, pays et code postal.
        
        En outre, nous enregistrons les demandes et devis que vous effectuez sur notre site.`,
                    en: `We collect information when you register on our site. The information collected includes your name, first name, company name, department, SIRET/SIREN number, email address, landline and mobile phone number, postal address, city, country and postal code.
        
        In addition, we record the requests and quotes that you make on our site.`,
                    sp: `Recopilamos información cuando se registra en nuestro sitio. La información recopilada incluye su nombre, apellido, nombre de su empresa, servicio, número de SIRET/SIREN, dirección de correo electrónico, número de teléfono fijo y móvil, dirección postal, ciudad, país y código postal.
        
        Además, registramos las solicitudes y presupuestos que realiza en nuestro sitio.`,
                    de: `Wir sammeln Informationen, wenn Sie sich auf unserer Website registrieren. Die gesammelten Informationen umfassen Ihren Namen, Vornamen, Firmennamen, Abteilung, SIRET/SIREN-Nummer, E-Mail-Adresse, Festnetz- und Mobiltelefonnummer, Postanschrift, Stadt, Land und Postleitzahl.
        
        Darüber hinaus zeichnen wir die Anfragen und Angebote auf, die Sie auf unserer Website tätigen.`
                }
            },
            {
                title: {
                    fr: `Utilisation des Informations`,
                    en: `Use of Information`,
                    sp: `Uso de la Información`,
                    de: `Verwendung von Informationen`
                },
                text: {
                    fr: `Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :`,
                    en: `All information we collect from you may be used for:`,
                    sp: `Toda la información que recopilamos de usted puede utilizarse para:`,
                    de: `Alle Informationen, die wir von Ihnen sammeln, können verwendet werden für:`
                },
                list: [
                    {
                        fr: `Personnaliser votre expérience et répondre à vos besoins individuels`,
                        en: `Personalize your experience and respond to your individual needs`,
                        sp: `Personalizar su experiencia y responder a sus necesidades individuales`,
                        de: `Personalisieren Sie Ihre Erfahrung und reagieren Sie auf Ihre individuellen Bedürfnisse`
                    },
                    {
                        fr: `Améliorer notre site Web`,
                        en: `Improve our website`,
                        sp: `Mejorar nuestro sitio web`,
                        de: `Verbessern Sie unsere Website`
                    },
                    {
                        fr: `Améliorer le service client et vos besoins de prise en charge`,
                        en: `Improve customer service and your support needs`,
                        sp: `Mejorar el servicio al cliente y sus necesidades de soporte`,
                        de: `Verbessern Sie den Kundenservice und Ihre Support-Anforderungen`
                    },
                    {
                        fr: `Vous contacter par e-mail ou téléphone`,
                        en: `Contact you by email or phone`,
                        sp: `Comunicarse con usted por correo electrónico o teléfono`,
                        de: `Kontaktieren Sie uns per E-Mail oder Telefon`
                    },
                    {
                        fr: `Traiter vos demandes et devis`,
                        en: `Process your requests and quotes`,
                        sp: `Procesar sus solicitudes y presupuestos`,
                        de: `Bearbeiten Sie Ihre Anfragen und Angebote`
                    }
                ]
            },
            {
                title: {
                    fr: `Confidentialité des Informations`,
                    en: `Information Confidentiality`,
                    sp: `Confidencialidad de la Información`,
                    de: `Vertraulichkeit von Informationen`
                },
                text: {
                    fr: `Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande et / ou une transaction, comme par exemple pour traiter vos demandes et devis.`,
                    en: `We are the sole owners of the information collected on this site. Your personal information will not be sold, exchanged, transferred, or given to any other company without your consent, except as necessary to respond to a request and/or transaction, such as processing your requests and quotes.`,
                    sp: `Somos los únicos propietarios de la información recopilada en este sitio. Su información personal no se venderá, intercambiará, transferirá ni cederá a ninguna otra empresa sin su consentimiento, salvo lo necesario para responder a una solicitud y/o transacción, como por ejemplo, para procesar sus solicitudes y presupuestos.`,
                    de: `Wir sind die alleinigen Eigentümer der auf dieser Website gesammelten Informationen. Ihre persönlichen Daten werden nicht verkauft, ausgetauscht, übertragen oder an ein anderes Unternehmen weitergegeben, es sei denn, dies ist erforderlich, um auf eine Anfrage und/oder eine Transaktion zu reagieren, z. B. um Ihre Anfragen und Angebote zu bearbeiten.`
                }
            },
            {
                title: {
                    fr: `Protection des Informations`,
                    en: `Information Protection`,
                    sp: `Protección de la Información`,
                    de: `Datenschutz`
                },
                text: {
                    fr: `Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, le service à la clientèle) ont accès aux informations personnelles identifiables.`,
                    en: `We implement a variety of security measures to maintain the safety of your personal information. Only employees who need to perform a specific job (for example, customer service) have access to personally identifiable information.`,
                    sp: `Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal. Solo los empleados que necesitan realizar un trabajo específico (por ejemplo, el servicio al cliente) tienen acceso a la información personal identificable.`,
                    de: `Wir setzen eine Vielzahl von Sicherheitsmaßnahmen ein, um die Sicherheit Ihrer persönlichen Daten zu gewährleisten. Nur Mitarbeiter, die eine bestimmte Aufgabe ausführen müssen (z. B. der Kundenservice), haben Zugriff auf personenbezogene Daten.`
                }
            },
            {
                title: {
                    fr: `Utilisation des Cookies`,
                    en: `Cookie Usage`,
                    sp: `Uso de Cookies`,
                    de: `Cookie-Nutzung`
                },
                text: {
                    fr: `Nous utilisons des cookies pour améliorer votre expérience sur notre site. Les cookies sont de petits fichiers texte stockés sur votre appareil lorsque vous visitez notre site.
        
        Nous utilisons des cookies de session pour la fonctionnalité "Se souvenir de moi" lors de la connexion. Ces cookies permettent de mémoriser votre identifiant et votre mot de passe pour vous éviter de les saisir à chaque fois que vous visitez notre site. Ces cookies sont temporaires et sont supprimés dès que vous fermez votre navigateur.
        
        Vous pouvez contrôler et gérer les cookies à l'aide des paramètres de votre navigateur. Vous pouvez choisir d'accepter ou de refuser tous les cookies, ou de les accepter au cas par cas. Cependant, le blocage des cookies de session peut empêcher la fonctionnalité "Se souvenir de moi" de fonctionner correctement.`,
                    en: `We use cookies to improve your experience on our site. Cookies are small text files stored on your device when you visit our site.
        
        We use session cookies for the "Remember me" functionality when logging in. These cookies allow us to remember your username and password so you don't have to enter them every time you visit our site. These cookies are temporary and are deleted as soon as you close your browser.
        
        You can control and manage cookies using your browser settings. You can choose to accept or reject all cookies, or to accept them on a case-by-case basis. However, blocking session cookies may prevent the "Remember me" functionality from working properly.`,
                    sp: `Utilizamos cookies para mejorar su experiencia en nuestro sitio. Las cookies son pequeños archivos de texto almacenados en su dispositivo cuando visita nuestro sitio.
        
        Utilizamos cookies de sesión para la funcionalidad "Recuérdame" al iniciar sesión. Estas cookies nos permiten recordar su nombre de usuario y contraseña para que no tenga que ingresarlos cada vez que visite nuestro sitio. Estas cookies son temporales y se eliminan tan pronto como cierra su navegador.
        
        Puede controlar y administrar las cookies mediante la configuración de su navegador. Puede elegir aceptar o rechazar todas las cookies, o aceptarlas caso por caso. Sin embargo, bloquear las cookies de sesión puede impedir que la funcionalidad "Recuérdame" funcione correctamente.`,
                    de: `Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden, wenn Sie unsere Website besuchen.
        
        Wir verwenden Sitzungscookies für die Funktion "Angemeldet bleiben" bei der Anmeldung. Diese Cookies ermöglichen es uns, Ihren Benutzernamen und Ihr Passwort zu speichern, damit Sie sie nicht jedes Mal eingeben müssen, wenn Sie unsere Website besuchen. Diese Cookies sind temporär und werden gelöscht, sobald Sie Ihren Browser schließen.
        
        Sie können Cookies über die Einstellungen Ihres Browsers steuern und verwalten. Sie können wählen, ob Sie alle Cookies akzeptieren oder ablehnen möchten oder ob Sie sie fallweise akzeptieren möchten. Wenn Sie jedoch Sitzungscookies blockieren, kann die Funktion "Angemeldet bleiben" möglicherweise nicht ordnungsgemäß funktionieren.`
                }
            },
            {
                title: {
                    fr: `Accès à vos Informations`,
                    en: `Access to Your Information`,
                    sp: `Acceso a su Información`,
                    de: `Zugriff auf Ihre Informationen`
                },
                text: {
                    fr: `Vous pouvez accéder à vos informations personnelles et les modifier à tout moment en vous connectant à votre compte et en accédant à la page des paramètres de compte.`,
                    en: `You can access and modify your personal information at any time by logging into your account and accessing the account settings page.`,
                    sp: `Puede acceder y modificar su información personal en cualquier momento iniciando sesión en su cuenta y accediendo a la página de configuración de la cuenta.`,
                    de: `Sie können jederzeit auf Ihre persönlichen Daten zugreifen und diese ändern, indem Sie sich in Ihr Konto einloggen und auf die Kontoeinstellungsseite zugreifen.`
                }
            },
            {
                title: {
                    fr: `Conservation des Informations`,
                    en: `Information Retention`,
                    sp: `Conservación de la Información`,
                    de: `Informationsspeicherung`
                },
                text: {
                    fr: `Nous conservons vos informations personnelles aussi longtemps que votre compte est actif ou que cela est nécessaire pour vous fournir des services. Nous conservons également vos demandes et devis dans l'historique de votre compte.`,
                    en: `We retain your personal information as long as your account is active or as needed to provide you services. We also retain your requests and quotes in your account history.`,
                    sp: `Conservamos su información personal mientras su cuenta esté activa o sea necesaria para brindarle servicios. También conservamos sus solicitudes y presupuestos en el historial de su cuenta.`,
                    de: `Wir speichern Ihre persönlichen Daten so lange, wie Ihr Konto aktiv ist oder wie es erforderlich ist, um Ihnen Dienstleistungen anzubieten. Wir speichern auch Ihre Anfragen und Angebote in Ihrem Kontoverlauf.`
                }
            },
            {
                title: {
                    fr: `Consentement`,
                    en: `Consent`,
                    sp: `Consentimiento`,
                    de: `Zustimmung`
                },
                text: {
                    fr: `En utilisant notre site, vous consentez à notre politique de confidentialité.`,
                    en: `By using our site, you consent to our privacy policy.`,
                    sp: `Al usar nuestro sitio, acepta nuestra política de privacidad.`,
                    de: `Durch die Nutzung unserer Website stimmen Sie unserer Datenschutzrichtlinie zu.`
                }
            },
        ],
        title_mdl_request_quote: {
            fr: `Demande de devis`,
            en: `Request a quote`,
            sp: `Solicitar un presupuesto`,
            de: `Angebot anfordern`
        },
        delivery_address: {
            fr: `Adresse de livraison`,
            en: `Delivery Address`,
            sp: `Dirección de entrega`,
            de: `Lieferadresse`
        },
        label_delivery_address: {
            fr: `Sélectionnez votre adresse de livraison :`,
            en: `Select your delivery address :`,
            sp: `Seleccione su dirección de entrega :`,
            de: `Wählen Sie Ihre Lieferadresse :`
        },
        specifics_needs: {
            fr: `Besoins spécifiques`,
            en: `Specific needs`,
            sp: `Necesidades específicas`,
            de: `Spezifische bedürfnisse`
        },
        label_specifics_qte: {
            fr: `Modifier la quantité souhaitée :`,
            en: `Modify the desired quantity :`,
            sp: `Modificar la cantidad deseada :`,
            de: `Menge ändern :`
        },
        label_specifics_needs: {
            fr: `Quels sont vos besoins spécifiques :`,
            en: `What are your specific needs :`,
            sp: `¿Cuáles son sus necesidades específicas :`,
            de: `Was sind Ihre spezifischen Bedürfnisse :`
        },
        error_delivery_address: {
            fr: `Adresse de livraison non sélectionnée`,
            en: `Delivery address not selected`,
            sp: `Dirección de entrega no seleccionada`,
            de: `Lieferadresse nicht ausgewählt`
        },
        title_historical: {
            fr: `Historique des demandes`,
            en: `Request History`,
            sp: `Historial de solicitudes`,
            de: `Anfrageverlauf`
        },
        opt_references: {
            fr: `Références`,
            en: `References`,
            sp: `Referencias`,
            de: `Referenzen`
        },
        btn_download_quote: {
            fr: `Télécharger le devis`,
            en: `Download quote`,
            sp: `Descargar cotización`,
            de: `Angebot herunterladen`
        },
        date: {
            fr: `Date`,
            en: `Date`,
            sp: `Fecha`,
            de: `Datum`
        },
        type: {
            fr: `Type`,
            en: `Type`,
            sp: `Tipo`,
            de: `Art`
        },
        product_or_service: {
            fr: `Produit/Service`,
            en: `Product/Service`,
            sp: `Producto/Servicio`,
            de: `Produkt/Dienstleistung`
        },
        title_mdl_request_por: {
            fr: `Demande spécifique`,
            en: `Specific Request`,
            sp: `Solicitud específica`,
            de: `Spezifische Anfrage`
        },
        msg_conf_mdl_request_por: {
            fr: `Votre demande a bien été transmise. 
            Notre équipe vous répondra au plus vite via votre adresse mail.`,
            en: `Your request has been successfully submitted. 
            Our team will respond to you as soon as possible via your email address.`,
            sp: `Su solicitud ha sido enviada con éxito. 
            Nuestro equipo le responderá lo antes posible a través de su dirección de correo electrónico.`,
            de: `Ihre Anfrage wurde erfolgreich übermittelt. 
            Unser Team wird Ihnen so schnell wie möglich per E-Mail antworten.`
        },
        msg_error_spe_need_require: {
            fr: `Sans une description de vos besoins, nous ne pouvons répondre à votre demande.`,
            en: `Without a description of your needs, we cannot respond to your request.`,
            sp: `Sin una descripción de sus necesidades, no podemos responder a su solicitud.`,
            de: `Ohne eine Beschreibung Ihrer Bedürfnisse können wir Ihre Anfrage nicht beantworten.`
        },
        btn_repeat_request: {
            fr: `Voir la demande`,
            en: `View request`,
            sp: `Ver solicitud`,
            de: `Anfrage ansehen`
        },
        _: {
            fr: ``,
            en: ``,
            sp: ``,
            de: ``
        },
    },
    reducers: {
        // pas besoin
    }
})

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        menu: menuSlice.reducer,
        data: dataSlice.reducer,
        trad: translateSlice.reducer,
    }
});