import { useSelector } from "react-redux";

export const useGetProductList = () => {
    const data = useSelector(state => state.data.products);

    const getProductList = (_id) => {
        return data.filter(element => element.parentId === _id);        
    };

    return getProductList;
};