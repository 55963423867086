import { useGoPage } from "../../hooks/useGoPage";

import "./style.css"

function MainMenu({ className, data, images, childRoute, windowWidth }) {
    const goPage = useGoPage();

    return (
        <ul className={`main_menu_${className}`}>
            {data.map((element, idx) => (
                <li key={idx}>

                    <button onClick={() => goPage(`${childRoute}/${element._id}`)} className='onglet'>
                        <div className='preview'>

                            {images[idx] && images[idx][`${windowWidth < 700 ? 'small_' : ''}image`] &&
                                <img
                                    src={images[idx][`${windowWidth < 700 ? 'small_' : ''}image`]}
                                    alt='Illustration'
                                />
                            }

                            {/* {childRoute === 'category' &&
                            images[idx] &&
                                images[idx][`${windowWidth < 700 ? 'small_' : ''}image`] &&
                                <img
                                    src={images[idx][`${windowWidth < 700 ? 'small_' : ''}image`]}
                                    alt='Illustration'
                                />
                            }

                            {childRoute !== 'category' &&
                                className !== 'prod-associated' &&
                                images[idx] &&
                                images[idx][0] &&
                                images[idx][0][`${windowWidth < 700 ? 'small_' : ''}image`] &&
                                <img
                                    src={images[idx][0][`${windowWidth < 700 ? 'small_' : ''}image`]}
                                    alt='Illustration'
                                />
                            }

                            {className === 'prod-associated' &&
                            images[idx] &&
                                images[idx][`${windowWidth < 700 ? 'small_' : ''}image`] &&
                                <img
                                    src={images[idx][`${windowWidth < 700 ? 'small_' : ''}image`]}
                                    alt='Illustration'
                                />
                            } */}
                        </div>

                        <span>{element.name}</span>
                    </button>

                </li>
            ))}
        </ul>
    )
}

export default MainMenu;