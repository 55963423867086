import { useSelector } from "react-redux";
import { useLogout } from "../../hooks/useLogout";
import { useGoPage } from "./../../hooks/useGoPage";

import BtnWithIcon from "../../buttons/BtnWithIcon";

import "./style.css"

import settings_icon from './../../assets/svg/settings_icon-nav.svg'
import cart_icon from './../../assets/svg/cart_icon-nav.svg'
import history_icon from './../../assets/svg/history_icon-nav.svg'
import logout_icon from './../../assets/svg/logout_icon-nav.svg'

function UserMenu() {
    const goPage = useGoPage();
    const kill_session = useLogout();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    const user_menu = useSelector(state => state.menu.user_menu);

    return user_menu && (
        <div className="user_menu">
            <BtnWithIcon
                label={trad.btn_page_settings[lang]}
                icon={settings_icon}
                onClick={() => goPage('settings')}
                className=''
            />

            <BtnWithIcon
                label={trad.btn_page_cart[lang]}
                icon={cart_icon}
                onClick={() => goPage('cart')}
                className=''
            />

            <BtnWithIcon
                label={trad.btn_page_history[lang]}
                icon={history_icon}
                onClick={() => goPage('historical')}
                className=''
            />

            <BtnWithIcon
                label={trad.btn_logout[lang]}
                icon={logout_icon}
                onClick={() => kill_session('')}
                className=''
            />
        </div>
    )
}

export default UserMenu;