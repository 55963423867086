import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useGetUser } from "../../hooks/useGetUser";

import FormCoordinates from './../../forms/FormCoordinates';
import FormAddress from './../../forms/FormAddress';
import FormAddressMail from './../../forms/FormAddressMail';
import FormPassword from './../../forms/FormPassword';
import VerifPassword from "../../modals/VerifPassword";

import "./style.css"

function Settings() {    
    const getUser = useGetUser();

    const user = useSelector(state => state.user);
    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);
    
    const [mdlConf, setMdlConf] = useState({ data: null, type: '' });
    const [userProfile, setUserProfile] = useState(null);

    useEffect(() => {
        if (!user || user.token === '') {
            setUserProfile(null);
            return
        }       

        const fetchProfile = async () => {            
            const profile = await getUser(user);
            setUserProfile(profile);
        }
        fetchProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return userProfile && (
        <div className='page_settings'>
            <h2>{trad.title_settings[lang]}</h2>
            <FormCoordinates data={userProfile} setMdlConf={setMdlConf} />
            <FormAddress data={userProfile.delivery_address} setUserProfile={setUserProfile} />
            <FormAddressMail data={userProfile} setMdlConf={setMdlConf} />
            <FormPassword setMdlConf={setMdlConf} />

            {mdlConf && mdlConf.data && <VerifPassword id={user._id} setup={mdlConf} setSetup={setMdlConf} />}
        </div>
    )
}

export default Settings;