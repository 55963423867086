import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useFetchImage } from "../../hooks/useFetchImage";
import { useFormatText } from "../../hooks/useFormatText";

import Banner from "../../components/Banner";
import Slider from "../../components/Slider";
import MainMenu from "../../components/MainMenu";
import Breadcrumb from "../../components/Breadcrumb";

import "./style.css"

function Category() {
    const { id } = useParams();

    const fetchImage = useFetchImage();
    const formatText = useFormatText();

    const [images, setImages] = useState([]);
    const [dataPage, setDataPage] = useState(null);
    const [childrenData, setChildrenData] = useState([]);

    const [typePage, setTypePage] = useState('');
    const [childRoute, setChildRoute] = useState('error');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const categories = useSelector(state => state.data.categories);
    const products = useSelector(state => state.data.products);
    const services = useSelector(state => state.data.services);

    // Récupération de la largeur de l'écran
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Récupération des données de page
    useEffect(() => {
        const category = categories.find(cat => cat._id === id);
        setDataPage(category);
    }, [id, categories]);

    // Récupération des éléments enfant
    useEffect(() => {
        let newChildrenData = [];

        newChildrenData = categories.filter(cat => cat.parentId === id && cat.type === 'prod');

        if (newChildrenData.length !== 0) {
            setChildRoute('category');
            setChildrenData(newChildrenData);
            setTypePage('prod');
            return
        }

        newChildrenData = categories.filter(cat => cat.parentId === id && cat.type === 'serv');

        if (newChildrenData.length !== 0) {
            setChildRoute('category');
            setChildrenData(newChildrenData);
            setTypePage('serv');
            return
        }

        newChildrenData = products.filter(prod => prod.parentId === id);

        if (newChildrenData.length !== 0) {
            setChildRoute('product');
            setChildrenData(newChildrenData);
            setTypePage('prod');
            return
        }

        newChildrenData = services.filter(serv => serv.parentId === id);

        if (newChildrenData.length !== 0) {
            setChildRoute('service');
            setChildrenData(newChildrenData);
            setTypePage('serv');
            return
        }

        setChildRoute('void');
        setTypePage('void');
        setChildrenData([]);

    }, [id, categories, products, services])

    // Récupération des images
    useEffect(() => {
        if (!childrenData || childrenData.length === 0) {
            setImages([]);
            return
        }

        const fetchImagesData = async () => {
            let newImages = [];


            if (childRoute === 'category') {
                for (let category of childrenData) {
                    if (category.image && category.image !== '') {
                        const categoryImg = await fetchImage(category.image);
                        newImages.push(categoryImg);
                    }
                }
            }
            else if (childRoute === 'product') {
                for (let product of childrenData) {
                    if (product.images.length !== 0 && product.images[0] !== null) {
                        const productImg = await fetchImage(product.images[0]);
                        newImages.push(productImg);
                    }
                }
            }
            else if (childRoute === 'service') {
                for (let service of childrenData) {
                    if (service.images.length !== 0 && service.images[0] !== null) {
                        const serviceImg = await fetchImage(service.images[0]);
                        newImages.push(serviceImg);
                    }
                }
            }

            setImages(newImages);
        }
        fetchImagesData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childrenData])

    return dataPage && childrenData.length !== 0 && images.length !== 0 && (
        <div className='page page_category'>
            {windowWidth > 767 && <Breadcrumb data={dataPage} />}

            <div className="container_slider_banner">
                <Slider type='category' data={childrenData} images={images} childRoute={childRoute} windowWidth={windowWidth} />
                <Banner windowWidth={windowWidth} />
            </div>

            <div className="container_main_menu">
                <h3>{dataPage.name}</h3>
                {dataPage.text && dataPage.text !== ' ' && <p>{formatText(dataPage.text)}</p>}

                <MainMenu
                    className={`${childRoute}${childRoute === 'category' ? '_' + typePage : ''}`}
                    data={childrenData}
                    images={images}
                    childRoute={childRoute}
                    windowWidth={windowWidth}
                />
            </div>
        </div>
    )
}

export default Category;