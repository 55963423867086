import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Layouts
import Loader from './layouts/Loader'
import Header from './layouts/Header'
import Nav from './layouts/Nav'
import Menu from './layouts/Menu'
import UserMenu from './layouts/UserMenu'
import Footer from './layouts/Footer'

// Pages
import Home from './pages/Home'
import Error from './pages/Error'

import Login from './pages/Login'

import Category from './pages/Category'
import Product from './pages/Product'
import Service from './pages/Service'

import Achievements from './pages/Achievements'
import Contact from './pages/Contact'
import About from './pages/About'

import Settings from './pages/Settings'
import Cart from './pages/Cart'
import Historical from './pages/Historical'

import Gc from './pages/Gc'
import Pp from './pages/Pp'

// Tools
import Lock from './components/Lock'

import './App.css'

function App() {
    const lock = useSelector(state => state.menu.lock);
    const loading = useSelector(state => state.menu.loading);

    return (
        <React.StrictMode>
            <Router>
                <Header />
                <Nav />
                <Menu />
                <UserMenu />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Error />} />
                    
                    <Route path="/login" element={<Login />} />
                    
                    <Route path="/category/:id" element={<Category />} />
                    <Route path="/product/:id" element={<Product />} />
                    <Route path="/service/:id" element={<Service />} />
                    
                    <Route path="/achievements" element={<Achievements />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/historical" element={<Historical />} />
                    
                    <Route path="/general_conditions_of_use" element={<Gc />} />
                    <Route path="/privacy_policy" element={<Pp />} />
                </Routes>

                <Footer />
                
                {lock && <Lock />}
                {loading !== 0 && <Loader />}
            </Router>
        </React.StrictMode>
    )
}

export default App;