import { useSelector } from "react-redux";
import { useState } from "react";

import Input from "../../components/Input";
import BtnCheck from "../../buttons/BtnCheck";

import { usePostLogin } from "../../hooks/usePostLogin";
import { useCheckFormData } from "../../hooks/useCheckFormData";

import "./style.css";

function FormLogin({ setShowForgetPassword }) {
    const checkFormData = useCheckFormData();
    const postLogin = usePostLogin();

    const trad = useSelector((state) => state.trad);
    const lang = useSelector((state) => state.menu.lang);

    const [formData, setFormData] = useState({ email: '', pw: '', save: false });
    const [alertAnime, setAlertAnime] = useState({ email: false, pw: false });
    const [msgError, setMsgError] = useState('');

    const formSubmit = async (e) => {
        e.preventDefault();

        const fields = ['email','pw'];

        const dataIsCheck = checkFormData(formData, setAlertAnime, fields);

        if (!dataIsCheck) {            
            setTimeout(() => {
                setAlertAnime({ email: false, pw: false });
            }, 2000)
            return
        }
        
        const response = await postLogin(formData);

        if (response !== '') {
            setMsgError(response);
        }
    };

    return (
        formData && (
            <form className="form_login" onSubmit={(e) => formSubmit(e)}>
                <span className="title_form">{trad.title_form_login[lang]}</span>

                <div className="container_input">
                    <Input
                        className="login"
                        subClass={alertAnime.email}
                        label={trad.label_email[lang] + " :"}
                        type="text"
                        placeholder={trad.placeholder_inp_email[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel="email"
                    />

                    <Input
                        className="login inp_pw"
                        subClass={alertAnime.pw}
                        label={trad.label_password[lang] + " :"}
                        type="password"
                        placeholder={trad.placeholder_inp_password[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel="pw"
                    />

                    <div className="login_options">
                        <BtnCheck
                            label={trad.check_remember_my[lang]}
                            stateLabel="save"
                            state={formData}
                            setState={setFormData}
                        />

                        <button
                            type="button"
                            className="btn_account_forget"
                            onClick={() => setShowForgetPassword(true)}
                        >
                            {trad.btn_forget_account[lang]}
                        </button>
                    </div>

                    {msgError && msgError !== '' &&
                        <p className="msg_error">
                            <i className="fas fa-triangle-exclamation"></i>
                            {msgError}
                        </p>
                    }

                    <button className="btn_submit" type="submit">
                        {trad.form_login_btn[lang]}
                    </button>

                </div>
            </form>
        )
    );
}

export default FormLogin;
