import "./style.css"

function BtnWithIcon({ label, icon, onClick, className }) {
    return (
        <button onClick={onClick} className={className}>
            {label !== '' && label}
            {icon && <img src={icon} alt="icon" />}
        </button>
    )
}

export default BtnWithIcon;