import { useSelector } from "react-redux";
import { useState } from "react";

import InputCode from "../../components/InputCode";

import { usePostSignup } from "../../hooks/usePostSignup";
import { useResendCode } from "../../hooks/useResendCode";

import "./style.css"

function ConfirmSignup({ formData }) {
    const postSignup = usePostSignup();
    const resendCode = useResendCode();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const [code, setCode] = useState('');
    const [msgError, setMsgError] = useState('');
    const [msgSuccess, setMsgSuccess] = useState('');

    const confSignup = async () => {
        const response = await postSignup(formData, code);

        if (response !== '') {
            setMsgError(response);

            setTimeout(() => {
                setMsgError('');
            }, 6000);
        }
    }

    const sendNewCode = async () => {
        const response = await resendCode(formData.email, 'signup');

        if (response === true) {
            setMsgSuccess(trad.msg_resend_code[lang]);

            setTimeout(() => {
                setMsgSuccess('');
            }, 6000);
            return
        }

        setMsgError(response);

        setTimeout(() => {
            setMsgError('');
        }, 6000);
    }

    return (
        <section className="mdl_confirm_signup">
            <div className="content">
                <span className="title_modal">{trad.title_mdl_signup[lang]}</span>
                <p className="text">{trad.text_mdl_signup[lang]}</p>

                <InputCode value={code} setValue={setCode} />

                {msgError && msgError !== '' &&
                    <p className='msg_error_conf'>
                        <i className="fas fa-triangle-exclamation"></i>
                        {msgError}
                    </p>
                }

                <button
                    type="button"
                    className='conf_signup'
                    onClick={() => confSignup()}
                >
                    {trad.btn_valid[lang]}
                </button>

                <button
                    type="button"
                    className="btn_resend_code"
                    onClick={() => sendNewCode()}
                >
                    {trad.btn_resend_code[lang]}
                </button>

                {msgSuccess && msgSuccess !== '' &&
                    <p className='msg_resend_success'>
                        <i className="fas fa-check"></i>
                        {msgSuccess + `"${formData.email}"`}
                    </p>
                }
            </div>
        </section>
    )
}

export default ConfirmSignup;