import "./style.css"

function Download({ data }) {
    
    return (
        <section className='sect_download'>
            
        </section>
    )
}

export default Download;