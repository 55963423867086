import "./style.css"

function ListCategories({ icon, list, selected, selector, onClick, inv }) {

    const check_list = list && list.length !== 0;

    return (
        <>
            <ul className='menu_list_desktop'>
                {check_list && list.map((e, idx) => (
                    <li key={idx}>

                        <button
                            onClick={onClick}
                            className={selected._id === e._id ? 'focus' : ''}
                            onMouseEnter={() => selector(prev => { return { ...prev, _id: e._id } })}
                        >
                            {e.name}
                            {icon && <img src={icon} alt='>' />}
                        </button>

                    </li>
                ))}
            </ul>

            {!inv && <i className="border"></i>}
        </>
    )
}

export default ListCategories;