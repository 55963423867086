import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Input from "../../components/Input";

import "./style.css"

import icon from './../../assets/svg/phone_settings-icon.svg';

function FormAddressMail({ data, setMdlConf }) {
    const lang = useSelector(state => state.menu.lang);
    const trad = useSelector(state => state.trad);

    const [editEmail, setEditEmail] = useState(false);
    
    const [lastEmail, setLastEmail] = useState(null);
    const [formData, setFormData] = useState('');

    const [msgConf, setMsgConf] = useState('');
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        setLastEmail(data ? data.email : null)
    }, [data])

    const updateEmail = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (formData === '' || typeof formData !== 'string') {
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 2000)
            return
        }

        if (!emailRegex.test(formData)) {
            setMsgConf(trad.error_format_email[lang]);
            setTimeout(() => {
                setMsgConf('');
            }, 2500)
            return
        }

        setMdlConf({ data: formData, type: 'email' });
    }

    return lastEmail && (
        <form className='form_settings form_address_mail'>
            <h3>
                <img src={icon} alt="Icon" />
                {trad.title_form_address_mail[lang]}
            </h3>

            <div className="container_inp">
                <Input
                    className='settings lock'
                    label={trad.label_email[lang] + ' :'}
                    type='text'
                    placeholder={trad.placeholder_inp_email[lang]}
                    value={lastEmail}
                    setValue={setLastEmail}
                    stateLabel=''
                    lock={true}
                />


                {!editEmail &&
                    <button
                        className='btn_edit'
                        onClick={() => setEditEmail(true)}
                    >
                        {trad.btn_edit_email[lang]}
                    </button>
                }

                {editEmail &&
                    <Input
                        className='settings'
                        subClass={alert}
                        label={trad.label_new_email[lang] + ' :'}
                        type='text'
                        placeholder={trad.placeholder_inp_new_email[lang]}
                        value={formData}
                        setValue={setFormData}
                        stateLabel=''
                    />
                }
            </div>

            {editEmail &&
                <div className="panel">
                    <button type='button' onClick={() => setEditEmail(false)} className='btn_red'>{trad.cancel[lang]}</button>
                    <button type='submit' onClick={(e) => updateEmail(e)} className='btn_blue'>{trad.edit[lang]}</button>
                </div>
            }

            {msgConf && msgConf !== '' &&
                <div className="msg_conf">
                    <div className="content">
                        <p>{msgConf}</p>
                    </div>
                </div>
            }
        </form>
    )
}

export default FormAddressMail;