import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGoPage } from "../../hooks/useGoPage";

import "./style.css"

import icon_home from './../../assets/svg/icon_home.svg';

function Breadcrumb({ data, windowWidth }) {
    const goPage = useGoPage();

    const trad = useSelector(state => state.trad);
    const lang = useSelector(state => state.menu.lang);

    const categories = useSelector(state => state.data.categories);

    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [className, setClassName] = useState('breadcrumb');

    useEffect(() => {
        if (!data) {
            setBreadcrumbList([]);
            return
        }

        let newList = getBreadcrumbData();
        setBreadcrumbList(newList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, categories]);

    useEffect(() => {
        if (!windowWidth) {
            return
        }

        if (windowWidth > 1200) {
            setClassName('breadcrumb')
        }

        if (windowWidth < 1201) {
            setClassName('breadcrumb breadcrumb_reduce')
        }

        if (windowWidth < 880) {
            setClassName('breadcrumb breadcrumb_ultra_reduce')
        }
    }, [windowWidth])

    function getBreadcrumbData() {
        let response = [];
        let actual = '';

        const firstParent = categories.find(cat => cat._id === data.parentId);

        if (!firstParent) {
            return response;
        }

        actual = firstParent.parentId || '';
        response.push(firstParent);

        for (let i = 0; i < 100; i++) {
            if (actual === '') {
                continue
            }

            // eslint-disable-next-line no-loop-func
            const parent = categories.find(cat => cat._id === actual);

            if (!parent) {
                actual = '';
                continue
            }

            response = [parent, ...response];
            actual = parent.parentId || '';
        }

        return response
    }

    return breadcrumbList && (
        <div className={className}>
            <ul>
                <li className='home_brcmb' onClick={() => goPage('')}><img src={icon_home} alt={trad.text_home[lang]} /></li>
                <li>{'>'}</li>
                {breadcrumbList.length !== 0 && breadcrumbList.map((cat, idx) => (
                    <li key={idx} className='btn_breadcrumb'>
                        <button onClick={() => goPage(`category/${cat._id}`)}>
                            {cat.name}
                        </button>
                        {'>'}
                    </li>
                ))}
                <li className='end_point'>{data.name}</li>
            </ul>
        </div>
    )
}

export default Breadcrumb;